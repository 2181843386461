import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alertservice/alert.service';
import { CommonModule } from '@angular/common';
import { environment } from '../../../environments/environment';
import { NavigationService } from '../../services/nav.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [RouterModule, TranslateModule, ReactiveFormsModule, CommonModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  newsLetterForm = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email])
  })
  public pebraApp: string = environment.appUrl
  public show = true
  public zuerichText = false
  public winterthurText = false
  public showFooter = false



  constructor(private services: ApiService, private alertServices: AlertService, private navigationService: NavigationService) {
    if(window.location.pathname === '/zuerich') {
      this.show = false
      this.zuerichText = true
      this.showFooter = true
    } else if (window.location.pathname === '/winterthur') {      
      this.show = false
      this.winterthurText = true
      this.showFooter = true
    } 
    else if (window.location.pathname === '/kontakt') {      
      this.showFooter = false
    } 
    else {
      this.show = true
    }
  }

  handleSubmitLetter() {
    if(this.newsLetterForm.valid) {
      if(this.newsLetterForm.get('email')?.hasError('required') && this.newsLetterForm.get('email')?.errors){
        console.log("error");
      } else {
        this.services.newsLetter(this.newsLetterForm.value).subscribe({
          next: (res) => {
            if (res) {
              this.alertServices.success("Erfolgreich eingetragen", { autoClose: true });
              this.newsLetterForm.get('email')?.setValue(null)      
            }
          },
          error: (err) => {
            if (err) {
              this.alertServices.error(err.error.error , {autoClose: true})
            }
          }
        }) 
      }
    } else {
      console.log("error"); 
    }
  }
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  navigateToServices() {
    this.navigationService.emitScrollToServices();
  }

  navigateToFaq() {
    this.navigationService.emitScrollToFaq();
  }

  navigateToComments() {
    this.navigationService.emitScrollToComments();
  }
  
  openFile() {
    window.open("../../../assets/terms/AGB_Pebra_Reinigung_Schweiz_AG_2024.pdf")
  }
  openDataFile() {
    window.open("../../../assets/data/Datenschutzerklarung_Pebra.pdf")
  }
}
