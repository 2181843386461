<div class="bg-[#346973] mx-3 md:mx-5 rounded-tl-3xl break-words hyphens-auto rounded-br-3xl">
  <div class="container mx-auto pt-10 pb-5 px-5 md:px-10 mb-16">
    <div
      class="flex flex-col md:flex-row md:mx-5 justify-between py-5 bg-green-secondary rounded-br-3xl rounded-tl-3xl"
    >
      <div class="py-5 md:w-[50%] mx-5 md:mx-10">
        <p
          class="bg-violet-secondary w-fit text-violet-brightpurple rounded-md uppercase px-5 font-bold font-sans md:mb-5"
        >
          newsletter
        </p>
        <h4 class="text-gray-duckfortiery">
          {{ "footer.title" | translate }}
        </h4>
      </div>
      <div class="md:py-5 mx-5 md:mx-10 md:w-[50%] md:mt-10">
        <p>
          {{ "footer.content" | translate }}
        </p>
        <form 
        [formGroup]="newsLetterForm"
        (submit)="handleSubmitLetter()"
        class="flex flex-col md:flex-row relative">
          <input
            formControlName="email"
            class="md:inline-block md:w-[70%] border-0 py-3 mt-5 mb-5 md:mb-0 pl-7 pr-20 bg-blue-secondary text-gray-ducktertiary placeholder:text-gray-ducktertiary md:rounded-bl-xl rounded-xl md:rounded-none"
            type="text"
            placeholder="{{ 'footer.placeholder' | translate }}"
          />
          <button
          type="submit"
            class="bg-turquoise-tertiary text-white md:w-[30%] w-[50%] p-3 md:rounded-tr-xl md:rounded-br-xl rounded-xl md:rounded-none md:mt-0 mt-2 self-end"
          >
            {{ "footer.subscribe-btn" | translate }}
          </button>
        </form>
      </div>
    </div>
    <div class="flex flex-col md:flex-row py-5 md:mx-5 text-white">
      <div class="p-5 md:w-[25%] mx-auto md:mx-0">
        <a routerLink="/">
          <img
            src="../../../assets/icons/pebra-header-logo.svg"
            class="w-full md:w-[50%] mb-3"
            alt="ranodm"
        /></a>
        <div class="flex gap-5">
          <a
            href="https://www.facebook.com/profile.php?id=61558889392837"
            target="_blank"
          >
            <img
              class="h-6"
              src="../../../assets/icons/facebook-outline.svg"
              alt="random"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/pebra-clean-more/?viewAsMember=true"
            target="_blank"
          >
            <img
              class="h-6"
              src="../../../assets/icons/linkedin-outline.svg"
              alt="random"
            />
          </a>
          <a href="https://www.instagram.com/pebra.clean.more/" target="_blank">
            <img
              class="h-6"
              src="../../../assets/icons/instagram-outline.svg"
              alt="random"
            />
          </a>
        </div>
      </div>
      <div class="p-5 md:w-[25%]">
        <h6>Navigation</h6>
        <a class="block" routerLink="/">{{ "general.home" | translate }}</a>
        <a class="block" href="https://{{pebraApp}}/private">{{ "general.cleaning" | translate }}</a>
        <a class="block" routerLink="FAQ">FAQ</a>
        <a class="block" routerLink="about">{{
          "general.about-us" | translate
        }}</a>
      </div>
      <div class="p-5 md:w-[25%]">
        <h6>{{ "footer.contact" | translate }}</h6>
        <p>Pebra Reinigung Schweiz AG Thurgauerstrasse 54 8050 Zürich</p>
        <div class="py-2">
          <img
            class="inline"
            src="../../../assets/icons/phone-footer.svg"
            alt="random"
          />
          <a href="tel:+41 44 451 04 72" class="inline ml-1"
            >+41 44 451 04 72</a
          >
        </div>
        <div class="py-2">
          <img
            class="inline"
            src="../../../assets/icons/mail-footer.svg"
            alt="random"
          />
          <a href="mailto: info@pebra.ch" class="inline ml-2"
            >info&#64;pebra.ch</a
          >
        </div>
      </div>
      <div class="p-5 md:w-[25%]">
        <h6>{{ "footer.opening-hours" | translate }}</h6>
        <p class="py-2">
          {{ "footer.working-days" | translate }}
        </p>
        <p>{{ "footer.payments" | translate }}</p>
      </div>
    </div>
    <div
      class="md:flex flex-col md:flex-row justify-between text-white py-5 mx-5"
    >
      <div>
        <p>&copy; 2024 Pebra | Produced by Stutz Medien AG & Bavox GmbH</p>
      </div>
      <div class="flex flex-col md:flex-row gap-2">
        <a class="cursor-pointer underline" href="/imprint">{{ 'data-protection-page.imprint' | translate }}</a>
        <p class="cursor-pointer underline" (click)="openFile()">{{ 'footer.terms' | translate }}</p>
      </div>
    </div>
  </div>
</div>
