<section class="mx-5 py-16 break-words hyphens-auto">
  <div class="rounded-tl-xl bg-green-primary rounded-br-xl">
    <div class="container mx-auto flex md:flex-row flex-col">
      <div class="self-center md:w-[50%] py-10">
        <div class="py-5 mx-10">
          <p
            class="bg-violet-secondary w-fit text-violet-brightpurple rounded-md uppercase px-5 font-bold font-sans mb-5"
          >
            {{ "career-component.career" | translate }}
          </p>
          <h4 class="text-gray-duckfortiery font-bold">
            Lorem ipsum dolor sit amet consectetur
          </h4>
        </div>
        <div class="mx-10">
          <p class="pb-5">
            Lorem ipsum dolor sit amet consectetur adipiscing elit semper dalar
            elementum tempus hac tellus liberoLorem ipsum dolor sit amet
            consectetur adipiscing elit semper dalar elementum tempus hac tellus
            libero
          </p>
          <button class="bg-turquoise-tertiary text-white p-3 rounded-xl">
            Lorem ipsum
          </button>
        </div>
      </div>
      <div class="md:w-[50%]">
        <img
          class="p-5 md:p-10"
          src="../../assets/images/homeImg.png"
          alt="img"
        />
      </div>
    </div>
  </div>
</section>
