<section
  class="bg-green-primary break-words hyphens-auto min-h-[70vh] 2xl:min-h-[50vh] mx-5 md:py-10 mb-14 relative rounded-tl-3xl rounded-br-3xl shadow-2xl overflow-hidden md:mt-16"
>
  <div
    class="container mx-auto flex flex-col md:flex-row justify-between md:py-10"
  >
    <div class="py-5 md:w-[50%] mx-5 md:mx-10">
      <p
        class="bg-violet-secondary w-fit text-violet-brightpurple rounded-md uppercase px-5 font-bold font-sans mb-5"
      >
        {{ "proccess-component.sequence" | translate }}
      </p>
      <h4 class="text-gray-duckfortiery font-bold">
        {{ "proccess-component.title" | translate }}
      </h4>
    </div>
    <div class="py-5 mx-5 md:mx-10 md:w-[50%] md:mt-10">
      <p class="md:py-5">
        1. {{ "proccess-component.firsttext" | translate }}
      </p>
      <p class="md:py-5">
        2. {{ "proccess-component.secondtext" | translate }}
      </p>
      <p class="md:py-5">
        3. {{ "proccess-component.thirdtext" | translate }}
      </p>
    </div>
  </div>
  <div
    class="container mx-auto w-full box scroll-smooth snap-y snap-mandatory overflow-auto mt-10"
  >
    <div class="hidden md:block absolute bottom-10 right-5">
      <img
        src="../../../../assets/icons/cleaning-boy-process.svg"
        alt="random"
      />
    </div>
  </div>
</section>
