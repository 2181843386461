import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  constructor(private httpClient: HttpClient) {}

  getZipCodes(): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}/cleanings/zip`).pipe(
      catchError((err: any) => {
        console.log(err);
        return throwError(err);
      })
    ).pipe(
      catchError((err: any) => {
        console.log(err);
        return throwError(err);
      })
    );
  }

  getReviewCards(): Observable<any> {
    return this.httpClient
      .get(`${environment.apiUrl}/reviews/display`)
      .pipe(
        catchError((err: any) => {
          console.log(err);
          return throwError(err);
        })
      );
  }

  postData(body: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiUrl}/reviews`, body).pipe(
      catchError((err: any) => {
        console.log(err);
        return throwError(err);
      })
    ).pipe(
      catchError((err: any) => {
        console.log(err);
        return throwError(err);
      })
    );
  }

  submitOffert(body: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiUrl}/users/promotional`, body,{
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ngrok-skip-browser-warning': '123'
      })
    }).pipe(
      catchError((err: any) => {
        console.log(err);
        return throwError(err);
      })
    ).pipe(
      catchError((err: any) => {
        console.log(err);
        return throwError(err);
      })
    );
  }

  unsupportedZips(body: any, zip: any): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/cleanings/zip/${zip}/request`, body)
      .pipe(
        catchError((err: any) => {
          console.log(err);
          return throwError(err);
        })
      );
  }
  
  newsLetter(body: any,): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/users/newsletter`, body)
      .pipe(
        catchError((err: any) => {
          console.log(err);
          return throwError(err);
        })
      );
  }
}
