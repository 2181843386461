<div
  class="mx-3 break-words hyphens-auto md:mx-5 md:pt-28 pt-10 flex flex-col min-h-[93vh] justify-center bg-gray-secondary-200"
>
  <div class="container mx-auto">
    <div class="flex flex-col justify-center mx-auto w-full px-2 md:w-[50%]">
      <h4>{{ "success-page.thank-you" | translate }}</h4>

      <p>
        {{ "general.go-to" | translate }}
        <a routerLink="/" class="text-turquoise-tertiary"
          >{{ "general.btn" | translate }}
        </a>
      </p>
      <div class="flex mx-auto">
        <img
          src="../../../assets/icons/cleaning-lady-home.svg"
          alt="random"
          class="w-40"
        />
        <img
          src="../../../assets/icons/cleaning-boy-home.svg"
          alt="random"
          class="w-36 mb-5"
        />
      </div>
    </div>
  </div>
</div>
