import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-error',
  standalone: true,
  imports: [RouterModule, TranslateModule],
  templateUrl: './error.component.html',
  styleUrl: './error.component.scss',
})
export class ErrorComponent implements OnInit {
  constructor(private activatedRoutes: ActivatedRoute) {}

  public message: string = '';

  ngOnInit(): void {
    this.activatedRoutes.queryParams.subscribe((params: any) => {
      let param = params;
      if (param && Object.keys(param).length > 0) {
        this.message = 'Diese Reinigung wurde bereits überprüft';
      } else {
        this.message = 'Error';
      }
    });
  }
}
