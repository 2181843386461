import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, inject, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AlertComponent } from "../../components/alert/alert.component";
import { NavigationService } from '../../services/nav.service';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-header',
    standalone: true,
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
    imports: [CommonModule, RouterModule, AlertComponent]
})
export class HeaderComponent {
  @Input() isOpen: boolean = false;
  @Output() isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() selectedLang: string = 'DE';
  @Input() handleLanguage!: () => void;
  @Output() selectedLangChange: EventEmitter<string> =
  new EventEmitter<string>();
  changeBg: boolean = false;
  public pebraApp: string = environment.appUrl
  public currentSection: string | null = 'home'
  public show = true

  getSelectedLang(): string {
    if (this.selectedLang === 'EN') {
      return 'English';
    } else if (this.selectedLang === 'DE') {
      return 'Deutsch';
    } else {
      return 'English';
    }
  }

  constructor(private navigationService: NavigationService) {
    if(window.location.pathname === '/zuerich' || window.location.pathname === '/winterthur') {
      this.show = false
    } else {
      this.show = true
    }
  }
  
  handleMenu() {
    this.isOpen = !this.isOpen;
    this.isOpenChange.emit(this.isOpen);
  }
  @HostListener('window:scroll', ['$event'])
  onScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    this.changeBg = scrollPosition > 50;

    let currentSection: string | null = null;
    const sections = document.querySelectorAll('section');
    const navbar = document.querySelector('#navbar') as HTMLElement;
    const navbarHeight = navbar ? navbar.offsetHeight : 0;
    sections.forEach((section) => {
      const sectionTop = section.getBoundingClientRect().top - navbarHeight;
      if (sectionTop < 50) {
        currentSection = section.getAttribute('id');
      }
    });
    this.currentSection = currentSection;
  }



  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  navigateToServices() {
    this.navigationService.emitScrollToServices();
  }
  
  navigateToFaq() {
    this.navigationService.emitScrollToFaq();
  }

  navigateToComments() {
    this.navigationService.emitScrollToComments();
  }
}
