@if(showFooter){

  @if (show) {
    <div class="bg-[#346973] mx-3 md:mx-5 rounded-tl-3xl break-words hyphens-auto rounded-br-3xl">
      <div class="container mx-auto pt-10 pb-5 px-5 md:px-10 mb-16">
        <div
          class="flex flex-col md:flex-row md:mx-5 justify-between py-5 bg-green-secondary rounded-br-3xl rounded-tl-3xl"
        >
          <div class="py-5 md:w-[50%] mx-5 md:mx-10">
            <p
              class="bg-violet-secondary w-fit text-violet-brightpurple rounded-md uppercase px-5 font-bold font-sans md:mb-5"
            >
              newsletter
            </p>
            <h4 class="text-gray-duckfortiery">
              {{ "footer.title" | translate }}
            </h4>
          </div>
          <div class="md:py-5 mx-5 md:mx-10 md:w-[50%] md:mt-10">
            <p>
              {{ "footer.content" | translate }}
            </p>
            <form 
            [formGroup]="newsLetterForm"
            (submit)="handleSubmitLetter()"
            class="flex flex-col md:flex-row relative">
              <input
                formControlName="email"
                class="md:inline-block md:w-[70%] border-0 py-3 mt-5 mb-5 md:mb-0 pl-7 pr-20 bg-blue-secondary text-gray-ducktertiary placeholder:text-gray-ducktertiary md:rounded-bl-xl rounded-xl md:rounded-none"
                type="text"
                placeholder="{{ 'footer.placeholder' | translate }}"
              />
              <button
              type="submit"
                class="bg-turquoise-tertiary text-white md:w-[30%] w-[50%] p-3 md:rounded-tr-xl md:rounded-br-xl rounded-xl md:rounded-none md:mt-0 mt-2 self-end"
              >
                {{ "footer.subscribe-btn" | translate }}
              </button>
            </form>
          </div>
        </div>
        <div class="flex flex-col md:flex-row py-5 md:mx-5 text-white">
          <div class="p-5 md:w-[25%] mx-auto md:mx-0">
            <a routerLink="/">
              <img
                src="../../../assets/icons/pebra-header-logo.svg"
                class="w-full md:w-[50%] mb-3"
                alt="ranodm"
            /></a>
            <div class="flex gap-5">
              <a
                href="https://www.facebook.com/profile.php?id=61558889392837"
                target="_blank"
              >
                <img
                  class="h-6"
                  src="../../../assets/icons/facebook-outline.svg"
                  alt="random"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/pebra-clean-more/?viewAsMember=true"
                target="_blank"
              >
                <img
                  class="h-6"
                  src="../../../assets/icons/linkedin-outline.svg"
                  alt="random"
                />
              </a>
              <a href="https://www.instagram.com/pebra.clean.more/" target="_blank">
                <img
                  class="h-6"
                  src="../../../assets/icons/instagram-outline.svg"
                  alt="random"
                />
              </a>
            </div>
          </div>
          <div class="p-5 md:w-[25%]">
            <h6>Navigation</h6>
            <a class="block" routerLink="/">{{ "general.home" | translate }}</a>
            <a class="block" href="https://{{pebraApp}}/private">{{ "general.cleaning" | translate }}</a>
            <a class="block" routerLink="FAQ">FAQ</a>
            <a class="block" routerLink="about">{{
              "general.about-us" | translate
            }}</a>
          </div>
          <div class="p-5 md:w-[25%]">
            <h6>{{ "footer.contact" | translate }}</h6>
            <p>Pebra Reinigung Schweiz AG Leutschenbachstrasse 41 8050 Zürich</p>
            <div class="py-2">
              <img
                class="inline"
                src="../../../assets/icons/phone-footer.svg"
                alt="random"
              />
              <a href="tel:+41 44 451 04 72" class="inline ml-1"
                >+41 44 451 04 72</a
              >
            </div>
            <div class="py-2">
              <img
                class="inline"
                src="../../../assets/icons/mail-footer.svg"
                alt="random"
              />
              <a href="mailto: info@pebra.ch" class="inline ml-2"
                >info&#64;pebra.ch</a
              >
            </div>
          </div>
          <div class="p-5 md:w-[25%]">
            <h6>{{ "footer.opening-hours" | translate }}</h6>
            <p class="py-2">
              {{ "footer.working-days" | translate }}
            </p>
            <p>{{ "footer.payments" | translate }}</p>
          </div>
        </div>
        <div
          class="md:flex flex-col md:flex-row justify-between text-white py-5 mx-5"
        >
          <div>
            <p>&copy; 2024 Pebra | Produced by Stutz Medien AG & Bavox GmbH</p>
          </div>
          <div class="flex flex-col md:flex-row gap-2">
            <a class="cursor-pointer underline" href="/imprint">{{ 'data-protection-page.imprint' | translate }}</a>
            <p class="cursor-pointer underline" (click)="openFile()">{{ 'footer.terms' | translate }}</p>
            <p class="cursor-pointer underline" (click)="openDataFile()">{{ 'footer.privacy' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  } @else {
    <div class="footer-bg">
      <div
        class="flex flex-col justify-center gap-8 items-center w-full sm:w-[80%] md:w-[80%] lg:w-[70%] footer-banner sm:rounded-tl-[24px] sm:rounded-br-[24px] p-4 sm:p-10 bg-[#00829ABF]"
      >
          <h4 class="text-[31px] sm:text-[42px] uppercase text-center font-medium text-white">
            Jetzt buchen und zurücklehnen
          </h4>
          @if (zuerichText) {
            <p class="text-[24px] font-medium text-center text-white">Worauf warten Sie? Buchen Sie noch heute Ihre Putzfrau in Zürich und geniessen Sie ein blitzsauberes Zuhause. Klicken Sie auf den Button unten und lassen Sie uns die Arbeit übernehmen!</p>
          } @else if(winterthurText) {
            <p class="text-[24px] font-medium text-center text-white">Worauf warten Sie? Buchen Sie noch heute Ihre Putzkraft in Winterthur und geniessen Sie ein blitzsauberes Zuhause. Klicken Sie auf den Button unten und lassen Sie uns die Arbeit übernehmen!</p>
          }
          <button (click)="scrollToTop()" class="btn-outline-new">OFFERTE ANFRAGEN</button>
      </div>
    </div>
  <div class="bg-turquoise-duckfortiery border-turquoise-duckfortiery border break-words hyphens-none ">
    <div class="md:mx-12 mx-5 py-10">
      <div class="flex flex-col md:flex-row justify-between py-5 text-white">
        <div class="py-5 md:w-[25%] flex flex-col gap-4 justify-between items-start">
          <div (click)="scrollToTop()">
            <img
              src="../../../assets/icons/pebra-header-logo.svg"
              class="w-[133px] mb-3 cursor-pointer"
              alt="ranodm"
          /></div>
          <div class="flex gap-5">
            <a href="https://www.facebook.com/people/Pebra-Clean-More/61558889392837/" target="_blank" class="hover-container">
              <img class="h-6 default-icon" src="../../../assets/icons/facebook-outline.svg" alt="facebookIcon" />
              <img class="h-6 hover-icon" src="../../../assets/icons/facebook-hover.svg" alt="facebookHover Icon" />
            </a>
            <a href="https://www.linkedin.com/company/pebra-clean-more/" target="_blank" class="hover-container">
              <img class="h-6 default-icon" src="../../../assets/icons/linkedin-outline.svg" alt="LinkedIn Icon" />
              <img class="h-6 hover-icon" src="../../../assets/icons/linkedin-hover.svg" alt="LinkedIn Hover Icon" />
            </a>
            <a href="https://www.instagram.com/pebra.lifestyle/" target="_blank" class="hover-container">
              <img class="h-6 default-icon" src="../../../assets/icons/instagram-outline.svg" alt="instagram Icon" />
              <img class="h-6 hover-icon" src="../../../assets/icons/instagram-hover.svg" alt="instagram Hover Icon" />
            </a>
          </div>
          <button (click)="scrollToTop()" class="btn-outline-new">OFFERTE ANFRAGEN</button>
        </div>
        <div class="py-5 flex flex-col font-medium justify-between md:w-[25%]">
          <h6 class="uppercase text-[20px]">Unternehmen</h6>
          <a class="block cursor-pointer text-[18px] hover:text-violet-brightpurple" (click)="scrollToTop()">Home</a>
          <a (click)="navigateToServices()" class="block cursor-pointer text-[18px] hover:text-violet-brightpurple">Unsere Leistungen</a>
          <a (click)="navigateToComments()" class="block cursor-pointer text-[18px] hover:text-violet-brightpurple">Kundenstimmen</a>
          <a (click)="navigateToFaq()" class="block cursor-pointer text-[18px] hover:text-violet-brightpurple" >FAQ</a>
        </div>
        <div class="py-5 flex flex-col justify-between items-start text-[18px] font-medium md:w-[25%]">
          <h6 class="uppercase">Kontakt</h6>
          <div class="py-2 ">
            <img
              class="inline"
              src="../../../assets/icons/phone-footer.svg"
              alt="random"
            />
            <a href="tel:+41 44 451 04 72" class="inline ml-1 hover:text-violet-brightpurple"
              >+41 44 451 04 72</a
            >
          </div>
          <div class="py-2">
            <img
              class="inline"
              src="../../../assets/icons/mail-footer.svg"
              alt="random"
            />
            <a href="mailto: info@pebra.ch" class="inline ml-2 hover:text-violet-brightpurple"
              >info&#64;pebra.ch</a
            >
          </div>
          <div class="py-2 flex items-center">
            <img
              class="inline"
              src="../../../assets/icons/location-footer.svg"
              alt="random"
            />
            <p class="inline ml-2"
              >Leutschenbachstrasse 41<br>8050 Zürich</p
            >
          </div>
        </div>
        <div class="py-5 flex font-medium text-[18px] flex-col justify-between md:w-[25%]">
          <h6 class="uppercase">Öffnungszeiten</h6>
          <p class="py-2">
            Telefonisch erreichbar jeweils Montag bis Freitag von 8:00 bis 17:00 Uhr
          </p>
          <p>Sichere Bezahlung durch Visa, Mastercard und Twint</p>
        </div>
      </div>
      <hr class="border-[1px] border-white my-5" />
      <div
        class="md:flex flex-col md:flex-row items-center justify-between text-white py-5"
      >
        <div class="py-5 lg:py-0 text-[14px]">
          <p>&copy; 2024 Pebra | Produced by  <a class="hover:text-violet-brightpurple" href="https://www.construct202.ch/" target="_blank">Construct202 GmbH</a> & <a class="hover:text-violet-brightpurple" href="https://www.bloom-rocket.ch/" target="_blank">Bloom Rocket GmbH</a></p>
        </div>
        <div class="flex flex-wrap flex-row text-[18px] font-medium gap-2">
          <a class="cursor-pointer hover:text-violet-brightpurple" href="/imprint">Impressum</a>
          <button class="cursor-pointer hover:text-violet-brightpurple" (click)="openFile()">Geschäftsbedingungen</button>
          <button class="cursor-pointer hover:text-violet-brightpurple" (click)="openDataFile()">Datenschutzrichtlinien</button>
        </div>
      </div>
    </div>
  </div>
  }
} @else {
  
}
