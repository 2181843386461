<div
  class="my-3 custom-z"
  [ngClass]="{
    'fixed top-0 right-3 w-11/12 md:w-96 z-50': absolute,
    'w-full': !absolute
  }"
>
  <div
    *ngFor="let alert of alerts"
    class="flex flex-nowrap items-center {{ cssClass(alert) }}"
    [ngClass]="transparent ? 'alert-transparent' : 'alert-filled'"
  >
    <img
      src="../../../assets/alerts/alert-{{ imgPath }}.svg"
      alt="Alert Icon"
    />
    <div *ngIf="alert.type != 4" class="px-4 alert-text">
      {{ alert.message }}
    </div>
    <div class="alert-text" *ngIf="alert.type == 4">
      {{ alert.message }} -
      <a class="underline text-link-hover" href="/individual/user-profile"
        >Subscribe now</a
      >
    </div>
    <button (click)="removeAlert(alert)" class="min-w-4 ml-auto">
      <img
        class="w-7"
        src="../../../assets/alerts/close-{{ imgPath }}.svg"
        alt="Close Alert"
      />
    </button>
  </div>
</div>
