<div class="mx-2 break-words hyphens-auto md:mx-5 bg-gray-secondary-200 mt-5">
  <section class="bg-gradient rounded-tl-3xl px-3 rounded-br-3xl relative">
    <div
      class="container mx-auto min-h-[90vh] pt-16 md:pt-0 flex flex-col md:flex-row md:justify-between md:py-10"
    >
      <div class="py-5 ml-5 md:ml-10 w-full md:mt-24 mt-6 md:w-[50%] md:mx-10">
        <h6
          class="bg-violet-secondary w-fit text-violet-brightpurple rounded-md uppercase px-5 font-bold font-sans mb-5"
        >
          {{ "about-us-page.banner.about-us" | translate }}
        </h6>
        <h4 class="font-bold p-2 text-green-secondary">
          {{ "about-us-page.banner.title" | translate }}
        </h4>
      </div>
      <div class="py-5 mx-5 md:mx-10 self-center md:w-[50%] md:mt-10">
        <p class="py-5 text-green-secondary">
          {{ "about-us-page.banner.desc" | translate }}
        </p>
        <!-- <button
          class="bg-turquoise-tertiary text-white font-bold p-[14px] rounded-xl rounded-br-xl"
        >
          {{ "about-us-page.banner.contact" | translate }}
        </button>
        <button
          class="bg-transparent ml-5 font-bold p-3 border-2 border-white text-white rounded-xl rounded-br-xl"
        >
          {{ "about-us-page.banner.location-map" | translate }}
        </button> -->
      </div>
    </div>
    <div class="hidden md:block">
      <img
        src="../../assets/icons/faq-banner.svg"
        class="absolute -bottom-1 left-0"
        alt="banner"
      />
      <img
        src="../../assets/icons/cleaning-boy-aboutus.svg"
        class="absolute bottom-10 left-0 z-10"
        alt="bannerfaq"
      />
      <img
        src="../../assets/icons/cleaning-boy-cicrcle.svg"
        class="absolute left-0 bottom-20"
        alt="bannerfaq"
      />
      <img
        src="../../assets/icons/cleaning-lady-aboutus.svg"
        class="absolute -bottom-7 left-56 z-20"
        alt="bannerfaq"
      />
      <img
        src="../../assets/icons/cleaning-lady-circle.svg"
        class="absolute bottom-0 left-56"
        alt="bannerfaq"
      />
    </div>
  </section>
  <section class="md:py-16">
    <div
      class="container mx-auto flex flex-col md:flex-row justify-between py-10"
    >
      <div class="py-5 md:w-[50%] relative mx-5 md:mx-10">
        <p
          class="bg-violet-secondary w-fit text-violet-brightpurple uppercase px-5 font-bold rounded-md font-sans mb-5"
        >
          {{ "about-us-page.stories-section.stories" | translate }}
        </p>
        <h4 class="text-gray-duckfortiery font-bold">
          {{ "about-us-page.stories-section.title" | translate }}
        </h4>
        <div class="absolute hidden md:block right-10 md:left-16 md:bottom-10">
          <img
            class="w-40"
            src="../../assets/icons/cleaning-lady-home2.svg"
            alt="random"
          />
        </div>
      </div>
      <div class="md:py-5 mx-5 md:mx-10 md:w-[50%] md:mt-10">
        <p class="md:py-5">
          {{ "about-us-page.stories-section.descone" | translate }}
        </p>
        <p class="md:py-5">
          {{ "about-us-page.stories-section.desctwo" | translate }}
        </p>
      </div>
    </div>
    <!-- <div class="container mx-auto py-5">
      <button class="bg-turquoise-tertiary text-white ml-5 p-3 rounded-lg">
        {{ "about-us-page.stories-section.location-map" | translate }}
      </button>
    </div> -->
  </section>
  <app-facts></app-facts>
  <div>
    <section class="md:py-16">
      <div
        class="container mx-auto relative flex flex-col md:flex-row justify-between py-10"
      >
        <div class="py-5 md:w-[50%] mx-5 md:mx-10">
          <p
            class="bg-violet-secondary w-fit text-violet-brightpurple uppercase px-5 font-bold rounded-md font-sans mb-5"
          >
            {{ "about-us-page.mission-section.mission" | translate }}
          </p>
          <h4 class="text-gray-duckfortiery font-bold">
            {{ "about-us-page.mission-section.title" | translate }}
          </h4>
          <div class="absolute hidden md:block right-10 md:left-16 md:bottom-20">
            <img
              class="w-40"
              src="../../assets/icons/cleaning-lady-home2.svg"
              alt="random"
            />
          </div>
        </div>
        <div class="md:py-5 mx-5 md:mx-10 md:w-[50%] md:mt-10">
          <ul class="md:py-5 list-disc ml-5 md:ml-10">
            <li class="py-2">
              {{ "about-us-page.mission-section.one" | translate }}
            </li>
            <li class="py-2">
              {{ "about-us-page.mission-section.two" | translate }}
            </li>
            <li class="py-2">
              {{ "about-us-page.mission-section.three" | translate }}
            </li>
            <li class="py-2">
              {{ "about-us-page.mission-section.four" | translate }}
            </li>
            <li class="py-2">
              {{ "about-us-page.mission-section.five" | translate }}
            </li>
            <li class="py-2">
              {{ "about-us-page.mission-section.six" | translate }}
            </li>
            <li class="py-2">
              {{ "about-us-page.mission-section.seven" | translate }}
            </li>
            <li class="py-2">
              {{ "about-us-page.mission-section.eight" | translate }}
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section class="md:py-16">
      <div class="container mx-auto">
        <div class="flex flex-col md:flex-row justify-between py-10">
          <div class="py-5 md:w-[50%] mx-5 md:mx-10">
            <p
              class="bg-violet-secondary w-fit text-violet-brightpurple uppercase px-5 font-bold rounded-md font-sans mb-5"
            >
              #wearepebra
            </p>
            <h4 class="text-gray-duckfortiery font-bold">
              {{ "about-us-page.pebra-team.title" | translate }}
            </h4>
          </div>
        </div>
        <div
          class="flex mx-5 md:mx-20 md:flex-row flex-col justify-between gap-5 py-12"
        >
          @for (card of directorCards; track card) {
          <div
            class="md:w-[25%] rounded-tl-2xl shadow-xl rounded-br-2xl text-gray-ducktertiary bg-white p-5"
          >
            <img
              class="mx-auto rounded-tl-lg rounded-br-lg h-[240px] w-[368px] object-cover"
              [src]="card.img"
              alt="homeimg"
            />

            <h6 class="font-bold pt-5">{{ card.title }}</h6>
            <p>{{'about-us-page.pebra-team.' + card.desc | translate}}</p>
            <!-- <p class="text-turquoise-tertiary font-bold pt-3">
              {{ card.phone }}
            </p>
            <p class="text-turquoise-tertiary font-bold">{{ card.email }}</p>
            <div class="py-5 flex gap-5">
              <img
                src="../../../assets/icons/facebook-aboutus.svg"
                alt="icons"
              />
              <img
                src="../../../assets/icons/twitter-aboutus.svg"
                alt="bannerfaq"
              />
              <img
                src="../../../assets/icons/instagram-aboutus.svg"
                alt="bannerfaq"
              />
              <img
                src="../../../assets/icons/linkedin-aboutus.svg"
                alt="bannerfaq"
              />
            </div> -->
          </div>
          }
        </div>
      </div>
  </section>
  <app-process></app-process>
  <app-card></app-card>
</div>
