<div
  class="mx-3 md:mx-5 break-words hyphens-auto md:pt-28 pt-10 flex flex-col min-h-[94vh] justify-center bg-gray-secondary-200"
>
  <div class="container mx-auto">
    <div class="flex flex-col justify-center mx-auto w-full px-2 md:w-[50%]">
      <h4>{{ message }}</h4>

      <p>
        {{ "general.go-to" | translate }}
        <a routerLink="/" class="text-turquoise-tertiary"
          >{{ "general.btn" | translate }}
        </a>
      </p>
      <div class="flex mx-auto">
        <img
          src="../../assets/icons/cleaning-lady-error.svg"
          alt="random"
          class="w-32"
        />
        <img
          src="../../assets/icons/cleaning-boy-error.svg"
          alt="random"
          class="w-40 -mb-2"
        />
      </div>
    </div>
  </div>
</div>
