import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { ApiService } from '../services/api.service';
import { FilterPipe } from '../pipes/filter/filter.pipe';

import SwiperCore from 'swiper';
import Swiper from 'swiper';
import { SwiperOptions }  from 'swiper/types'
import { Navigation, Pagination } from 'swiper/modules'

SwiperCore.use([Navigation, Pagination]);
// Components
import { FactsComponent } from '../components/companyfacts/facts.component';
import { ProcessComponent } from '../components/companyprocess/process.component';
import { AlertService } from '../services/alertservice/alert.service';
import { environment } from '../../environments/environment';
import { CarouselComponent } from "../components/carousel/carousel.component";


@Component({
    selector: 'app-home',
    standalone: true,
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss',
    imports: [
        CommonModule,
        FactsComponent,
        ProcessComponent,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        FilterPipe,
        CarouselComponent,
    ]
})

export class HomeComponent implements OnInit, AfterViewInit {
  @ViewChild('swiperContainerr', { static: false }) swiperContainerRef!: ElementRef;
  @ViewChild('firstSection') firstSection!: ElementRef;
  public slides: any[] = [
    {
      src: '../../../assets/images/bavox.png',
    },
    {
      src: '../../../assets/images/verbally.png',
    },
    {
      src: '../../assets/images/re.png',
    },
    {
      src: '../../assets/images/stadtzwerge.png',
    },
    {
      src: '../../assets/images/vaudoise.png',
    },
    {
      src: '../../assets/images/threesixzero.png',
    },
    {
      src: '../../assets/images/cartonaurio2.png',
    }
  ];

  serviceCards = [
    {
      service: 'Für alle dies eilig haben',
      title: 'Expressreinigung',
      desc: 'Sofortige Expressreinigung: Flexibel, spontan, makellos – für blitzsaubere Räume in kürzester Zeit.',
    },
    {
      service: 'Das maximum für Ihren Lifestyle',
      title: 'Regelmässige Reinigung',
      desc: 'Immer wieder: Zuverlässige Reinigung für ein Zuhause, das stets frisch und einladend aussieht.',
    },
    {
      service: 'Für einen sauberen neuen Lebensabschnitt',
      title: 'Endreinigung',
      desc: 'Für ein makelloses Finish: Gründliche Reinigung, um Ihre Räumlichkeiten in einem Top-Zustand zu übergeben.',
    },
    {
      service: 'Unsere Sauberkeit, ihr Erfolg',
      title: 'Gewerbe- & Büroreinigung',
      desc: 'Effiziente Reinigung für professionell gepflegte Räume und eine optimale Arbeitsumgebung.',
    },
  ];

  currentCards = [
    {
      current: 'express',
      title: 'Lorem ipsum dolor sit amet',
      desc: 'Lorem ipsum dolor sit amet consectetur adipiscing elit semper dalar elementum tempus hac tellus liberoLorem ipsum dolor sit amet consectetur adipiscing elit semper dalar elementum tempus hac tellus libero',
    },
    {
      current: 'express',
      title: 'Lorem ipsum dolor sit amet',
      desc: 'Lorem ipsum dolor sit amet consectetur adipiscing elit semper dalar elementum tempus hac tellus liberoLorem ipsum dolor sit amet consectetur adipiscing elit semper dalar elementum tempus hac tellus libero',
    },
  ];

  public swiperParams!: SwiperOptions;
  private swiper!: Swiper;

  modalOpen: boolean = false;
  rating: number[] = [1, 2, 3, 4, 5];
  ratingStars: number = 0;
  alertPopUp: boolean = false;
  zipCode: any[] = [];
  allZips: any
  searchText: any = '';
  reservation: any = '';
  private: boolean = true;
  pursue: boolean = false;
  firstNum: number = 0;
  secondNum: number = 0;
  correctAnswer: number = 0;
  enableSubmit: boolean = true;
  public pebraApp: string = environment.appUrl
  displayDropdown: boolean = false;

  constructor(
    private apiService: ApiService,
    private alertService: AlertService
  ) {}

  form = new FormGroup({
    name: new FormControl(null, Validators.required),
    email: new FormControl(null, [Validators.required, Validators.email]),
    description: new FormControl(null, [Validators.required]),
    rating: new FormControl(0, [Validators.required]),
    userAnswer: new FormControl('', [Validators.required]),
  });

  zipAreaForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  /**
   * generates captcha for reviews
   */

  generateCaptcha() {
    this.firstNum = Math.floor(Math.random() * 25);
    this.secondNum = Math.floor(Math.random() * 25);
    this.correctAnswer = this.firstNum + this.secondNum;
  }

  ngOnInit() {
    this.generateCaptcha();
    this.apiService.getZipCodes().subscribe(
      (res: any) => {

        let mappedzips = res.data.map((zipData: any) => {
          return { ...zipData, cityZip: `${zipData.zip} ${zipData.city}` };
        });  
        const onlyZips = res.data.map((zipData: any) => {
          return  zipData.zip;
        });
        this.allZips = onlyZips       
        this.zipCode.push(mappedzips);
      },
      (error) => {
        console.error('Error fetching zip codes:', error);
      }
    );   
  }

  ngAfterViewInit() {
    this.initializeSwiper();
  }

  /**
   * swipper functions
   */

  initializeSwiper() {
    this.swiperParams = {
      slidesPerView: 4,
      loop: true,
      grabCursor: true,
      spaceBetween: 20,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        200: {
          slidesPerView: 1,
        },
        851: {
          slidesPerView: 3, 
        },
        1224: {
          slidesPerView: 4, 
        },
      },
      on: {
        init: () => {
          // console.log('Swiper initialized');
        }
      }
    };

    this.swiper = new Swiper(this.swiperContainerRef.nativeElement, this.swiperParams);
  }

  slideNext() {
    this.swiper.slideNext();
  }

  slidePrev() {
    this.swiper.slidePrev();
  }

  handleCaptcha() {
    const answer: any = this.form.get('userAnswer')?.value;
    if (this.correctAnswer === +answer) {
      this.enableSubmit = false;
    } else {
      this.enableSubmit = true;
    }
  }

  /**
   * handle zips
   */

  handleSearchText() {
    this.displayDropdown = true;
  }

  setSearchText(zip: any, city: any) {
    this.reservation = zip;
    this.searchText = zip + ' ' + city;
    this.displayDropdown = false;
  }

  handleReservationButton() {    
    if (this.searchText.length >= 4) {
      this.reservation = this.searchText.slice(0, 4)
      if (this.reservation !== '' && this.allZips.includes(+this.reservation)) {
        this.navigateTo(this.reservation);
        this.reservation = '';
        this.searchText = '';
      } else {
        this.alertPopUp = true;
      }
    } 
  }

  handlePursueBooking() {
    this.private = false;
    this.pursue = true;
  }

  handlePrivateBooking() {
    this.pursue = false;
    this.private = true;
  }

  navigateTo(zip: string) {
    console.log(zip);
    if (this.private) {
      window.location.href = `https://${this.pebraApp}/?zip=${zip}`;
    } else {
      window.location.href =`https://${this.pebraApp}/company?zip=${zip}`;
    }
  }
 
  /**
   * review functions
  */
 
 openModal() {
   this.modalOpen = true;
 }
 
  closeModal() {
    this.modalOpen = false;
    this.form.get('rating')?.setValue((this.ratingStars = 0));
    this.form.reset();
  }
  
  closePopUp() {
    this.alertPopUp = false;
    this.zipAreaForm.reset();
    this.searchText = '';
    this.reservation = '';
  }
  
  rate(star: number) {
    this.ratingStars = star;
    this.form.get('rating')?.setValue(this.ratingStars);
  }

  submitReview() {
    Object.keys(this.form.controls).forEach((field) => {
      const control = this.form.get(field);

      if (control?.errors && control?.untouched) {
        control.setErrors({ required: true });
        control.markAsTouched();
      }
    });
    if (this.form.valid) {
      // position: data.position || undefined,
      const data: any = this.form.value;
      const filteredData = {
        name: data.name,
        email: data.email,
        rating: data.rating,
        description: data.description,
      };

      this.apiService.postData(filteredData).subscribe(
        (response) => {
          this.alertService.success("Ihre Bewertung wurde übermittelt", {autoClose: true})
          this.generateCaptcha();
        },
        (error) => {
          this.alertService.error(error.error.error, {autoClose: true})
        }
      );
      this.closeModal();
    }
  }

  submitEmail() {
    if (this.zipAreaForm.valid) {
      this.apiService
        .unsupportedZips(
          { email: this.zipAreaForm.get('email')?.value },
          this.searchText
        )
        .subscribe({
          next: (res) => {
            if (res) {
              this.alertService.success("Erfolgreich eingetragen", { autoClose: true });
              this.searchText = '';
              this.reservation = '';
              this.alertPopUp = false;
              this.zipAreaForm.reset();
            }
          },
          error: (err) => {
            if (err) {
              // console.log(err);
              // this.alertService.error(err.error.error, { autoClose: true });
            }
          },
        });
      this.zipAreaForm.reset();
    }
  }

  bookCleaning() {
    window.location.href = `https://${this.pebraApp}/`;
  }

  scrollTo() {
    if (this.firstSection) {
      this.firstSection.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }
}
