import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import SwiperCore from 'swiper';
import { SwiperOptions }  from 'swiper/types'
import { CommonModule } from '@angular/common';
import { ApiService } from '../../services/api.service';
import { Navigation, Pagination } from 'swiper/modules'
// Install Swiper modules
SwiperCore.use([Navigation, Pagination]);

@Component({
  selector: 'app-carousel',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  @ViewChild('swiperContainer') swiperContainerRef!: ElementRef;

  public slides: any[] = [];
  public swiper: any;
  public swiperParams!: SwiperOptions;

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.fetchReviewCards();
  }

  fetchReviewCards() {
    this.apiService.getReviewCards().subscribe(
      (res: any) => {
        this.slides = res.data;
        this.slides.sort((a: any, b: any) => {
          const dateA = new Date(a.createdAT).getTime();
          const dateB = new Date(b.createdAT).getTime();
          return dateA - dateB;
        });
        this.updateSwiper();
      },
      (error) => {
        console.error('Error fetching reviews:', error);
      }
    );
  }

  updateSwiper() {
    if (this.swiper) {
      this.swiper.destroy();
    }

    this.swiperParams = {
      slidesPerView: 3,
      loop: true,
      spaceBetween: 50,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        200: {
          slidesPerView: 1,
        },
        851: {
          slidesPerView: 2,
        },
        1224: {
          slidesPerView: 3,
        },
      },
      on: {
        init: () => {
          // console.log('Swiper initialized');
        }
      }
    };

    this.swiper = new SwiperCore(this.swiperContainerRef.nativeElement, this.swiperParams);
  }
}
