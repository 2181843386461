import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AlertComponent } from "../../components/alert/alert.component";

@Component({
    selector: 'app-header',
    standalone: true,
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
    imports: [CommonModule, RouterModule, AlertComponent]
})
export class HeaderComponent {
  @Input() isOpen: boolean = false;
  @Output() isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() selectedLang: string = 'DE';
  @Input() handleLanguage!: () => void;
  @Output() selectedLangChange: EventEmitter<string> =
    new EventEmitter<string>();
  changeBg: boolean = false;
  getSelectedLang(): string {
    if (this.selectedLang === 'EN') {
      return 'English';
    } else if (this.selectedLang === 'DE') {
      return 'Deutsch';
    } else {
      return 'English';
    }
  }
  
  handleMenu() {
    this.isOpen = !this.isOpen;
    this.isOpenChange.emit(this.isOpen);
  }
  @HostListener('window:scroll', ['$event'])
  onScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    this.changeBg = scrollPosition > 50;
  }
}
