<section class="mx-3 md:mx-5 pt-10 relative">
    <div class="rounded-tl-3xl bg-green-primary">
        <div class="container mx-auto px-5 md:px-0">
            <div class="mt-5">
                <h5 class="uppercase pt-10">{{ 'data-protection-page.imprint' | translate }}</h5>
                <p class="py-5">{{ 'data-protection-page.content' | translate }}</p>
                <p>{{ 'data-protection-page.firstaddress' | translate }}</p>
                <p>{{ 'data-protection-page.secondaddress' | translate }}</p>
                <p>8050 Zürich</p>
                <a href="mailto: info@pebra.ch" class="underline">info&#64;pebra.ch</a>
            </div>
            <div class="py-5">
                <h6>{{ 'data-protection-page.copyright' | translate }}</h6>
                <p class="md:w-[600px]">{{ 'data-protection-page.copyrightcontent' | translate }}</p>
                <p>{{ 'data-protection-page.firstaddress' | translate }}</p>
                <p>{{ 'data-protection-page.secondaddress' | translate }}</p>
                <p>8050 Zürich</p>
            </div>
            <div class="pb-5">
                <h6>{{ 'data-protection-page.disclaimer' | translate }}</h6>
                <p class="md:w-[600px]">{{ 'data-protection-page.firstdisclaimer' | translate }}</p>
                <p class="md:w-[600px] pt-3">{{ 'data-protection-page.seconddisclaimer' | translate }}</p>
            </div>
        </div>
    </div>
</section>