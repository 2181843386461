<div
  class="bg-[#FFFFFF] break-words hyphens-none mt-5 overflow-x-hidden"
>
  <section id="home" class="min-h-[100vh] mt-5 relative home-bg">
    <div class="container mx-auto flex">
      <div class="z-30 2xl:mx-24 min-h-[92vh] md:w-full rounded-tl-3xl">
        <div
          class="mx-0 w-full absolute center md:mx-5 md:mr-0 z-20 md:max-w-[50%]"
        >
          <div
          class="bg-gradient-new sm:max-w-[528px] min-h-[500px] 2xl:max-w-[580px] 2xl:min-h-[530px] flex flex-col gap-7 sm:gap-10 lg:justify-between justify-center sm:rounded-tl-3xl -mt-20 sm:mt-0 sm:rounded-br-3xl p-5 2xl:p-10 relative"
          >
            <img class="w-[256px] sm:block hidden absolute z-10 mt-10 top-1/2 -translate-y-1/2 -right-[78px]" src="../../assets/images/pebraquality.png" alt="pebraquality" />
            <h4 class="!text-[38px] md:!text-[54px] text-left break-words uppercase font-normal text-white">
              Jetzt Reinigung Anfragen
            </h4>
            <ul class="text-white ul">
              <li class="text-[18px]">Expressreinigungen möglich</li>
              <li class="text-[18px]">Ausgebildetes Reinigungspersonal</li>
              <li class="text-[18px]">Individuelle Lösungen</li>
              <li class="text-[18px]">Einfache Onlinebuchung</li>
            </ul>
            
            <!-- <app-hubspot-form></app-hubspot-form> -->
            <button (click)="navigateToKontakt()" 
            class='rounded-tl-md p-4 self-start shadow-none md:shadow-xl lg:mb-10 bg-white border-[#61BFD19f] md:border-transparent lg:border-transparent border-[1px] rounded-br-md flex flex-col gap-2 justify-center items-center w-fit cursor-pointer hover:border-[#61BFD19f] hover:bg-[#61BFD1] hover:text-white'
            >Jetzt Kontaktieren</button>
            <!-- <form (submit)="submitForm()" [formGroup]="form" class="md:p-5 sm:block flex flex-col sm:w-full">
              <div class="relative">
                <input
                  id="name"
                  type="text"
                  placeholder="Name"
                  class="sm:inline-block outline-none block w-full sm:w-[70%] border-0 py-3 mb-5 pl-7 pr-20 bg-blue-secondary text-gray-ducktertiary placeholder:text-gray-ducktertiary sm:rounded-[4px] rounded-[4px]"
                  formControlName="name"
                />
                <span
                  *ngIf="
                    form.get('name')?.hasError('required') &&
                    form.get('name')?.touched
                  "
                  class="text-red-500 absolute bottom-0 left-0"
                  >Name ist erforderlich
                </span>
              </div>
              <div class="relative">
                <input
                  id="email"
                  type="email"
                  placeholder="Email"
                  class="sm:inline-block outline-none block w-full sm:w-[70%] border-0 py-3 mb-5 pl-7 pr-20 bg-blue-secondary text-gray-ducktertiary placeholder:text-gray-ducktertiary sm:rounded-[4px] rounded-[4px]"
                  formControlName="email"
                />
                <span
                *ngIf="
                  form.get('email')?.touched && 
                  (form.get('email')?.hasError('required') || form.get('email')?.hasError('email'))
                "
                class="text-red-500 absolute bottom-0 left-0"
              >
                <span *ngIf="form.get('email')?.hasError('required')">Email ist erforderlich</span>
                <span *ngIf="form.get('email')?.hasError('email')">Ungültiges E-Mail-Format</span>
              </span>
              </div>
              <div class="relative">
                <input
                  class="sm:inline-block outline-none block w-full sm:w-[70%] border-0 py-3 mb-5 pl-7 pr-20 bg-blue-secondary text-gray-ducktertiary placeholder:text-gray-ducktertiary sm:rounded-[4px] rounded-[4px]"
                  type="text"
                  id="phone"
                  formControlName="phone"
                  placeholder="Telefonnummer"
                />
                <span
                *ngIf="
                    form.get('phone')?.hasError('required') &&
                    form.get('phone')?.touched
                  "
                  class="text-red-500 absolute bottom-0 left-0"
                  >
                  Telefonnummer ist erforderlich
                </span>
              </div>
              <button 
                type="submit"
                class="bg-turquoise-duckfortiery hover:bg-violet-brightpurple w-full sm:w-[30%] p-3 mt-5 sm:rounded-tr-[4px] sm:rounded-br-[4px] text-white rounded-[4px] sm:rounded-none sm:mt-0 self-end"
              >
                Einreichen
              </button>
            </form> -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="py-5 container mx-auto overflow-hidden">
    <h4 class="text-center w-full md:py-7 py-0 text-turquoise-duckfortiery font-medium uppercase !text-[32px] sm:!text-[48px]">Wie es funktioniert</h4>
    <div
      class="md:mx-auto flex flex-col justify-between"
    >
      <div
        class="flex sm:flex-row flex-wrap flex-col justify-between sm:justify-center lg:justify-between break-words hyphens-none"
      >
        @for (services of serviceCards; track services; let index = $index) {
        <div
          class="sm:w-[49%] lg:w-[33%] p-5"
        >
          <img class="w-[100px] h-[113px] mx-auto" src={{services.icon}} alt={{services.title}}>
          <h6 class="text-center w-full text-turquoise-duckfortiery font-medium uppercase !text-[18px] sm:!text-[24px]"><p class="h-[32px] w-[32px] text-[20px] inline-block rounded-full border-[2px] border-[#61366E]">{{index + 1}}</p> {{services.title}}</h6>
          <p class="mx-auto text-center w-[265px]">{{services.service}}</p>
        </div>
        }
      </div>
      <button
        class="bg-turquoise-duckfortiery block mx-auto uppercase my-10 text-white hover:bg-violet-brightpurple font-medium text-[16px] px-[20px] py-[16px] rounded-[12px]"
        (click)="scrollToTop()"
      >
        JETZT OFFERTE ANFRAGEN
      </button>
    </div>
  </div>
  <section id="servicesSection" #servicesSection>
    <div class="bg-green-primary break-words overflow-hidden hyphens-none py-5">
      <div
        class="container mx-auto md:py-10 overflow-hidden"
      >
        <h4 class="text-center w-full text-turquoise-duckfortiery font-medium uppercase !text-[32px] sm:!text-[48px]">Unsere Leistungen</h4>
        <div class="flex sm:flex-row flex-wrap flex-col justify-between md:pt-12 mt-5 break-words hyphens-none">
          @for (card of currentCards; track card; let index = $index) {
            <div
            (click)="scrollToTop()"
            class="lg:w-[49%] cursor-pointer p-10 sm:hover:scale-105 ease-in-out transition flex flex-col gap-4"
            >
              <h6 class="w-full text-turquoise-duckfortiery font-medium uppercase !text-[18px] sm:!text-[24px]">{{card.title}}</h6>
              <img src={{card.img}} alt={{card.title}}>
              <p>{{card.desc}}</p>
            </div>
          }
        </div>
        <button
        class="bg-turquoise-duckfortiery block mx-auto max-w-[200px] text-center uppercase my-10 text-white hover:bg-violet-brightpurple font-medium text-[16px] px-[20px] py-[16px] rounded-[12px]"
        (click)="scrollToTop()"
      >
        OFFERTE ANFRAGEN
      </button>
      </div>
    </div>
    <div class="bg-turquoise-duckfortiery py-16 overflow-hidden">
        <div class="container mx-auto">
          <h4 class="text-center w-full text-white font-medium uppercase !text-[32px] sm:!text-[48px]">Warum Pebra wählen?</h4>
          <div class="mx-5 flex flex-wrap flex-row gap-4 my-10">
            @for (cards of pebraCards; track cards; let index = $index) {
              <div
              class="xl:w-[24%] sm:w-[48%] cursor-pointer w-full bg-white rounded-tl-[12px] rounded-br-[12px] sm:hover:scale-105 transition ease-in-out p-5"
              >
                <img class="w-[120px] h-[139px] mx-auto" src={{cards.img}} alt={{cards.title}}>
                <h6 class="w-full mx-auto text-center text-turquoise-duckfortiery font-medium uppercase !text-[18px] sm:!text-[24px]">{{cards.title}}</h6>
                <p class="text-center">{{cards.desc}}</p>
              </div>
            }
          </div>
        </div>
    </div>
  </section>
  <section id="commentSection" #commentSection >
    <div class="pt-10 overflow-hidden">
      <div class="py-10 md:container mx-auto">
        <h4 class="text-center w-full text-turquoise-duckfortiery font-medium uppercase mb-10 !text-[32px] sm:!text-[48px]">Das sagen unsere Kunden</h4>
        <div class="swiper-container md:mx-16" #swiperContainer>
          <div class="swiper-wrapper">
              <div class="swiper-slide" *ngFor="let card of slides;">
                  <div class="slide-content mx-auto h-[405px] lg:h-[380px] w-[322px] lg:w-[350px] relative pt-5 rounded-tl-3xl bg-[#E6F3F573] shadow-lg shadow-[#00829A24] rounded-br-3xl p-5 cursor-grab">
                      <div class="flex gap-2 mt-2">
                          <ng-container *ngFor="let rating of [].constructor(card.rating)">
                              <img class="inline"
                                  src="../../assets/icons/home-star-new.svg"
                                  alt="star" />
                          </ng-container>
                      </div>
                      <img class="py-5" src="../../../assets/icons/quotes-end.svg" alt="quotes">
                      <p class="pt-5">“{{ card.description }}“</p>
                      <div class="absolute bottom-0 left-5">
                          <p class="text-[18px] font-medium pb-5">{{ card.name }}</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="md:mx-5 pt-10 flex justify-center gap-4">
          <button (click)="swiper.slidePrev()" class="h-[42px] flex items-center justify-center w-[42px] rounded-full bg-turquoise-duckfortiery hover:bg-violet-brightpurple">
              <img src="../../assets/icons/home-chevron-left-new.svg" alt="Previous" />
          </button>
          <button (click)="swiper.slideNext()" class="h-[42px] w-[42px] flex items-center justify-center rounded-full bg-turquoise-duckfortiery hover:bg-violet-brightpurple">
              <img class="" src="../../assets/icons/chevron-right-new.svg" alt="Next" />
          </button>
      </div>
      </div>
    </div>
    <div class="break-words bg-turquoise-duckfortiery hyphens-none overflow-hidden">
      <div class="py-16">
        <div class="container mx-auto">
          <h4 class="text-center mx-auto w-full text-white font-medium uppercase !text-[32px] sm:!text-[48px]">Zahlen & Fakten</h4>
          <p class="text-center mx-auto text-white w-full px-5 my-5 max-w-[992px]">Seit über 15 Jahren ist Pebra führend in der Schweizer Reinigungsbranche. Mit über 100 engagierten Mitarbeitenden bieten wir Lösungen für bereits über 10'000 zufriedene Privatkunden. Vertrauen Sie auf unsere Erfahrung und Kompetenz für erstklassige Sauberkeit und Service.</p>
          <div class="mx-5 flex flex-wrap flex-row gap-4 my-10">
            @for (cards of factsCards; track cards; let index = $index) {
              <div
              class="xl:w-[24%] cursor-pointer sm:w-[48%] w-full bg-turquoise-tertiary rounded-tl-[12px] rounded-br-[12px] hover:scale-105 ease-in-out transition p-5"
              >
                <img class="w-[148px] h-[140px] mx-auto" [src]="cards.img" alt={{cards.title}}>
                <h6 class="w-full mx-auto text-center text-white font-medium uppercase !text-[18px] sm:!text-[20px]">{{cards.title}}</h6>
              </div>
            }
          </div>
        </div> 
      </div>
    </div>
  </section>
  <section id="faqSection" #faqSection class="container mx-auto py-16 overflow-hidden">
    <div class="mx-5">
      <h4 class="text-center mx-auto w-full max-w-[714px] text-turquoise-duckfortiery font-medium uppercase !text-[32px] sm:!text-[48px]">Häufig gestellte Fragen</h4>
      <div class="mt-10">
        @for(kategorie of faqData; track kategorie; let index = $index) {
          <div
            class="rounded-br-2xl md:mx-auto mb-3 rounded-tl-2xl transition ease-in-out transform duration-300 border border-turquoise-duckfortiery"
          >
            <div (click)="handleContent(index)" class="flex p-10 items-center justify-between cursor-pointer">
              <h5 class="text-turquoise-duckfortiery uppercase text-[20px] w-[70%] font-medium">
                {{kategorie.title}}
              </h5>
              <div class="sm:h-[42px] h-[24px] flex items-center justify-center sm:w-[42px] w-[24px] rounded-full bg-turquoise-duckfortiery hover:bg-violet-brightpurple">
                <img
                  class="cursor-pointer w-[20px] sm:w-full"
                  [ngClass]="{
                    'transition ease-in-out transform duration-300': true,
                    '!-rotate-180': kategorie.showContent
                  }"
                  src="../../../assets/icons/chevron-down-new.svg"
                  alt="random"
                />
              </div>
            </div>
            <div *ngIf="kategorie.showContent" class="px-10 pb-10">
              <p>
                {{kategorie.desc}}
              </p>
            </div>
          </div>
          }
      </div>
    </div>
  </section>
</div>