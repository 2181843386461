<app-header
  class="fixed w-full top-0 z-30"
  (isOpenChange)="handleIsOpenChange($event)"
  [selectedLang]="selectedLanguage ? selectedLanguage : 'DE'"
  [handleLanguage]="handleOpenLanguage.bind(this)"
  [isOpen]="isOpen"
></app-header>

<div
  [ngClass]="{
    'w-[20%] header-container-open': isLanguageOpen,
    'header-container-closed ': !isLanguageOpen,
    'bg-[#61BFD8] text-white fixed rounded-lg top-20 z-30 right-[110px] max-w-[160px]': true
  }"
>
  <button
    class="w-full text-start cursor-pointer hover:bg-[#134379] hover:duration-300 p-4 rounded-t-lg"
    (click)="selectLang('DE')"
  >
    Deutsch
  </button>
  <button
    class="w-full text-start cursor-pointer hover:bg-[#134379] hover:duration-300 p-4 rounded-b-lg"
    (click)="selectLang('EN')"
  >
    English
  </button>
</div>
<div    
  [ngClass]="{
    'transition ease transform duration-300': true,
    'opacity-0 duration-150': isOpen
  }"
>
<router-outlet>
</router-outlet>
</div>
<div
  class="bg-gradient min-w-full min-h-screen"
  [ngClass]="{
    'w-[100%] header-container-open p-20 ': isOpen,
    'header-container-closed ': !isOpen,
    'fixed top-0 bottom-0 min-h-screen max-w-screen': true
  }"
>
  <div class="flex justify-between relative">
    <div class="w-full hidden md:block text-[77px]">
      <img
        src="../assets/icons/cleaning-lady-home.svg"
        alt="janies"
        class="absolute w-64 top-[25%] left-0"
      />
      <img
        src="../assets/icons/cleaning-boy-home.svg"
        alt="janies"
        class="absolute w-64 top-[15%] left-[20%]"
      />
    </div>
    <div class="flex mx-auto flex-col pt-16 md:items-end w-full gap-4">
      <a
        (click)="handleIsOpenChange(!isOpen)"
        routerLink="/"
        class="text-[48px] text-green-brightgreen hover:text-white hover:duration-100 font-bold"
      >
        {{ "general.home" | translate }}
      </a>
      <a
        (click)="handleIsOpenChange(!isOpen)"
        href="https://{{pebraApp}}/private"
        class="text-[48px] text-green-brightgreen hover:text-white hover:duration-100 text-left md:text-right font-bold"
      >
        {{ "general.cleaning" | translate }}
      </a>
      <a
        (click)="handleIsOpenChange(!isOpen)"
        routerLink="FAQ"
        class="text-[48px] text-green-brightgreen hover:text-white hover:duration-100 font-bold"
      >
        FAQ
      </a>
      <a
        (click)="handleIsOpenChange(!isOpen)"
        routerLink="about"
        class="text-[48px] text-green-brightgreen hover:text-white hover:duration-100 font-bold"
      >
        {{ "general.about-us" | translate }}
      </a>
    </div>
  </div>
</div>
<app-footer></app-footer>
<div class="py-2 text-primary-100 items-center fixed bottom-0 w-full">
  @if(isOpen) {
  <nav class="flex mx-10 justify-between self-center">
    <a
      class="p-2 rounded-xl border-white border-2 text-white "
      href="#review"
      >{{ "header.reviews" | translate }}
      <img
        class="inline pb-1 ml-2"
        src="../assets/icons/header-start-outline.svg"
        alt="header-star"
    /></a>
    <a
      class="p-2 rounded-xl border-white border-2 cursor-pointer z-50 text-white "
      href="https://{{pebraApp}}/login"
      >{{ "header.login" | translate }}
      <img
        class="inline pb-1 ml-2"
        src="../assets/icons/header-login-outline.svg"
        alt="header-login"
    /></a>
  </nav>
  } @else {
  <nav
    class="flex md:px-10 py-2 w-full fixed bottom-0 justify-between self-center px-2 bg-white"
  >
    <a
      class="text-[#346973] border-2 border-[#346973] p-1 md:p-2 ml-3 md:ml-0 rounded-xl"
      href="#review"
      >{{ "header.reviews" | translate }}
      <img
        class="inline pb-1 ml-1"
        src="../assets/icons/header-star.svg"
        alt="header-star"
    /></a>

    <a
      *ngIf="showButton"
      [ngClass]="{
        'transition ease-in-out transform duration-1000 ': true,
        
      }"
      class="p-1 px-2 md:p-2 rounded-xl text-white bg-violet-brightpurple"
      href="https://{{pebraApp}}/private"
      >{{'general.Reinigung reservieren' | translate}}</a
    >
    @if(loginBtn) {
    <a
      class="text-[#346973] border-2 border-[#346973] p-1 md:p-2 mr-3 md:mr-0 rounded-xl"
      href="https://{{pebraApp}}/profile"
      >{{'general.profile' | translate}}
      <img
        class="inline pb-1 ml-1"
        src="../assets/icons/circleheader-login.svg"
        alt="login-circle"
    /></a>
    } @else {
    <a
      class="text-[#346973] border-2 border-[#346973] p-1 md:p-2 mr-3 md:mr-0 rounded-xl"
      href="https://{{pebraApp}}/login"
      >{{ "header.login" | translate }}
      <img
        class="inline pb-1 ml-1"
        src="../assets/icons/circleheader-login.svg"
        alt="login-circle"
    /></a>
    }
  </nav>
  }
</div>
<div class="relative"></div>
