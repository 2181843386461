<div class="swiper-container md:mx-16" #swiperContainer>
    <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let card of slides; let odd = odd; let even = even">
            <div [ngClass]="{
              'bg-turquoise-duckfortiery text-blue-50': odd,
              'bg-white text-[#5F5F5F]': even
          }"
                class="slide-content h-[300px] relative pt-5 rounded-tl-2xl shadow-xl rounded-br-2xl p-5 cursor-grab">
                <div class="flex gap-3 mt-2">
                    <ng-container *ngFor="let rating of [].constructor(card.rating)">
                        <img class="inline"
                            [src]="odd ? '../../assets/icons/home-star.svg' : '../../assets/icons/home-star-blue.svg'"
                            alt="star" />
                    </ng-container>
                </div>
                <p class="pt-5">“{{ card.description }}“</p>
                <div class="absolute bottom-0 left-5">
                    <p [ngClass]="{
                      'text-blue-50': odd,
                      'text-turquoise-duckfortiery': even
                  }" class="lg-bold font-bold pb-5">
                        {{ card.name }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="md:mx-5 flex justify-between">
    <img class="rotate-180 pt-2 cursor-pointer" (click)="swiper.slidePrev()" src="../../assets/icons/home-chevron-right.svg" alt="Previous" />
    <img class="pt-7 cursor-pointer" (click)="swiper.slideNext()" src="../../assets/icons/home-chevron-right.svg" alt="Next" />
</div>
