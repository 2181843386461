import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { CommonModule } from '@angular/common';

import { ApiService } from '../services/api.service';
import { FilterPipe } from '../pipes/filter/filter.pipe';
import SwiperCore from 'swiper';
import { SwiperOptions }  from 'swiper/types'
import { Navigation, Pagination } from 'swiper/modules'



// Components
import { AlertService } from '../services/alertservice/alert.service';
import { environment } from '../../environments/environment';
import { Subscription } from 'rxjs';
import { NavigationService } from '../services/nav.service';

SwiperCore.use([Navigation, Pagination]);
@Component({
  selector: 'app-winterthur',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FilterPipe,
],
  templateUrl: './winterthur.component.html',
  styleUrl: './winterthur.component.scss'
})
export class WinterthurComponent {
  @ViewChild('servicesSection') servicesSection!: ElementRef;
  @ViewChild('commentSection') commentSection!: ElementRef;
  @ViewChild('faqSection') faqSection!: ElementRef;
  @ViewChild('swiperContainer') swiperContainerRef!: ElementRef;

  private subscription!: Subscription;
  private subscriptionFaq!: Subscription;
  private subscriptionComments!: Subscription;

  
  public slides: any[] = [];
  public swiper: any;
  public swiperParams!: SwiperOptions;
  public pebraApp: string = environment.appUrl
  public services: boolean = false
  alertPopUp: boolean = false;
  zipCode: any[] = [];
  allZips: any
  searchText: any = '';
  reservation: any = '';
  enableSubmit: boolean = true;
  displayDropdown: boolean = false;
  zipAreaForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  serviceCards = [
    {
      title: 'anfrage stellen',
      service: 'Füllen Sie unser Online-Formular aus und geben Sie an, welche Aufgaben Ihre Putzkraft in Winterthur übernehmen soll',
      icon: '../../assets/icons/service-location.jpg',
    },
    {
      title: 'Putzhilfe zuweisen',
      service: 'Wir wählen die perfekte Reinigungskraft aus unserem geprüften Team für Sie aus.',
      icon: '../../assets/icons/service-booking.svg',
    },
    {
      title: 'Termin bestätigen',
      service: 'Sie erhalten eine Bestätigung für Ihren Wunschtermin.',
      icon: '../../assets/icons/calendar-appointment.svg',
    },
  ];

  currentCards = [
    {
      title: 'Expressreinigung',
      desc: 'Benötigen Sie kurzfristige Unterstützung? Unsere Putzfrau in Winterthur steht Ihnen innerhalb von 48 Stunden (Werktage) zur Verfügung. Perfekt für einen schnellen Frischekick.',
      img: '../../assets/images/image.png'
    },
    {
      title: 'Regelmässige Reinigung',
      desc: 'Mehr Sauberkeit und mehr Freizeit. Unsere Putzhilfe in Winterthur sorgt wöchentlich, alle zwei Wochen oder monatlich für ein sauberes Zuhause. Ganz nach Ihren Wünschen.',
      img: '../../assets/images/image1.png'
    },
    {
      title: 'Spezialreinigungen',
      desc: 'Unsere Putzhilfen in Winterthur erledigen selbst anspruchsvolle Reinigungsarbeiten präzise - von Fensterreinigung über Umzugsreinigung mit Abnahmegarantie bis hin zur Grundreinigung für ein gepflegtes und sauberes Zuhause.',
      img: '../../assets/images/image2.png'
    },
    {
      title: 'Zusätzliche Dienstleistungen',
      desc: 'Neben unseren Hauptservices bieten wir auch spezialisierte Reinigungsdienste wie Teppichreinigung, Polsterpflege und die Desinfektion von viel genutzen Bereichen. Perfekt für Kunden mit speziellen Bedürfnissen.',
      img: '../../assets/images/image3.png'
    },
  ];

  pebraCards = [
    {
      title: 'Ihre Reinigungskraft',
      desc: 'Erhalten Sie eine feste Putzkraft in Winterthur, die Ihre Wünsche und Anforderungen kennt – für maximale Zufriedenheit.',
      img: '../../assets/icons/cleaninglady.png'
    },
    {
      title: 'Sicher und sorglos',
      desc: 'Unsere Putzhilfe in Winterthur wird sorgfältig ausgewählt. Sollte Ihre Reinigungskraft einmal ausfallen, sorgen wir umgehend für eine zuverlässige Vertretung.',
      img: '../../assets/icons/pebra-lock.svg'
    },
    {
      title: 'Transparente Preise',
      desc: 'Keine versteckten Kosten: Bei uns zahlen Sie fair und nachvollziehbar für Ihre Reinigung.',
      img: '../../assets/icons/pabra-phone.svg'
    },
    {
      title: 'Geschultes Personal',
      desc: 'Unsere Putzkräfte in Winterthur sind geschult und garantieren gründliche, hochwertige Ergebnisse.',
      img: '../../assets/icons/cleaningboy.png'
    },
  ]

    factsCards = [
    {
      title: '>15 jahre erfahrung',
      img: '/assets/icons/experience.svg'
    },
    {
      title: '>100 Mitarbeiter',
      img: '/assets/icons/100.png'
    },
    {
      title: '>10k zufriedene Privatkunden',
      img: '/assets/icons/happy-customer.svg'
    },
    {
      title: '>2k Geschäftskunden',
      img: '/assets/icons/business.svg'
    },
  ]

  faqData = [
    {
      title:'Wie buche ich eine Putzfrau in Winterthur?',
      desc: 'Nutzen Sie einfach unser Online-Buchungssystem. In wenigen Minuten ist Ihre Anfrage gestellt, und wir kümmern uns um den Rest. Sobald Ihre Anfrage bei uns eingegangen ist, prüfen wir Ihre Wünsche und Bedürfnisse, um die passende Putzfrau für Sie zu finden. Anschliessend erhalten Sie eine Bestätigung mit allen relevanten Details, damit Sie sich entspannt zurücklehnen können.',
      showContent: true,
    },
    {
      title: 'Welche Aufgaben übernimmt die Putzhilfe?',
      desc: 'Unsere Putzhilfe in Winterthur kann alle gängigen Reinigungsarbeiten erledigen, von Staubwischen bis hin zu Fensterreinigung. Dazu zählen auch das Saugen, Wischen, die Reinigung von Bädern und Küchen sowie andere alltägliche Aufgaben. Sollten Sie individuelle Wünsche oder besondere Anforderungen haben, können Sie diese ganz einfach bei der Buchung angeben. Unsere Mitarbeiter passen sich flexibel an Ihre Bedürfnisse an.',
      showContent: false,
    },
    {
      title:'Kann ich regelmässige Reinigungen buchen?',
      desc: 'Natürlich! Wir bieten flexible Abonnements für regelmässige Reinigungen – wöchentlich, zweiwöchentlich oder monatlich. Sie können den Rhythmus wählen, der am besten zu Ihrem Alltag passt. Unsere festen Reinigungskräfte sorgen dafür, dass Ihr Zuhause kontinuierlich sauber und gepflegt bleibt. Änderungen oder Anpassungen Ihres Abonnements sind jederzeit möglich.',
      showContent: false,
    },
    {
      title: 'Was passiert, wenn ich mit der Reinigung unzufrieden bin?',
      desc: 'Ihre Zufriedenheit ist uns wichtig. Sollte etwas nicht Ihren Vorstellungen entsprechen, lösen wir das Problem schnell und unkompliziert. Kontaktieren Sie uns einfach und teilen Sie uns Ihre Bedenken mit. Wir bieten entweder eine Nachbesserung an oder finden eine alternative Lösung, um sicherzustellen, dass Sie mit dem Ergebnis zufrieden sind.',
      showContent: false,
    },
    {
      title: 'Sind die Reinigungskräfte versichert?',
      desc: 'Ja, alle unsere Putzfrauen in Winterthur sind vollständig versichert. Das bedeutet, dass Sie bei eventuellen Schäden oder Unfällen während der Reinigung vollständig abgesichert sind. Unsere Versicherung deckt sowohl Schäden an Ihrem Eigentum als auch eventuelle Verletzungen der Reinigungskräfte ab, damit Sie sorgenfrei unseren Service nutzen können.',
      showContent: false,
    },
    {
      title: 'Wie kurzfristig kann ich eine Reinigung buchen?',
      desc: 'Sie können uns auch für kurzfristige Anfragen kontaktieren. Unser Express-Service steht innerhalb von 48 Stunden zur Verfügung. Sollten Sie besonders dringende Anforderungen haben, setzen wir alles daran, Ihnen so schnell wie möglich zu helfen. Kontaktieren Sie uns einfach, und wir organisieren eine Reinigungskraft, die Ihren Wunschtermin erfüllt.',
      showContent: false,
    },
  ];




  constructor(
    private apiService: ApiService,
    private alertService: AlertService,
    private navigationService: NavigationService
  ) {}

  ngOnInit() {
    this.subscription = this.navigationService.scrollToServices$.subscribe(() => {
      this.scrollToServices();
    });
    
    this.subscriptionFaq = this.navigationService.scrollToFaq$.subscribe(() => {
      this.scrollToFaq();
    });

    this.subscriptionComments = this.navigationService.scrollToComments$.subscribe(() => {
      this.scrollToComments();
    });

    this.apiService.getZipCodes().subscribe(
      (res: any) => {

        let mappedzips = res.data.map((zipData: any) => {
          return { ...zipData, cityZip: `${zipData.zip} ${zipData.city}` };
        });  
        const onlyZips = res.data.map((zipData: any) => {
          return  zipData.zip;
        });
        this.allZips = onlyZips       
        this.zipCode.push(mappedzips);
      },
      (error) => {
        console.error('Error fetching zip codes:', error);
      }
    ); 
    
    this.fetchReviewCards();
  }


  fetchReviewCards() {
    this.apiService.getReviewCards().subscribe(
      (res: any) => {
        this.slides = res.data;
        this.slides.sort((a: any, b: any) => {
          const dateA = new Date(a.createdAT).getTime();
          const dateB = new Date(b.createdAT).getTime();
          return dateA - dateB;
        });
        this.updateSwiper();
      },
      (error) => {
        console.error('Error fetching reviews:', error);
      }
    );
  }

  handleSearchText() {
    this.displayDropdown = true;
  }

  setSearchText(zip: any, city: any) {
    this.reservation = zip;
    this.searchText = zip + ' ' + city;
    this.displayDropdown = false;
  }

  handleReservationButton() {    
    if (this.searchText.length >= 4) {
      this.reservation = this.searchText.slice(0, 4)
      if (this.reservation !== '' && this.allZips.includes(+this.reservation)) {
        this.navigateTo(this.reservation);
        this.reservation = '';
        this.searchText = '';
      } else {
        this.alertPopUp = true;
      }
    } 
  }

  navigateTo(zip: string) {
    window.location.href = `https://${this.pebraApp}/?zip=${zip}&region=winterthur`;
  }
 
  closePopUp() {
    this.alertPopUp = false;
    this.zipAreaForm.reset();
    this.searchText = '';
    this.reservation = '';
  }

  handleContent(index: any) {

    const isCurrentlyOpen = this.faqData[index].showContent;

    this.faqData.forEach((item) => (item.showContent = false));

    if (!isCurrentlyOpen) {
      this.faqData[index].showContent = true;
    }
  }
  
  submitEmail() {
    if (this.zipAreaForm.valid) {
      this.apiService
        .unsupportedZips(
          { email: this.zipAreaForm.get('email')?.value },
          this.searchText
        )
        .subscribe({
          next: (res) => {
            if (res) {
              this.alertService.success("Erfolgreich eingetragen", { autoClose: true });
              this.searchText = '';
              this.reservation = '';
              this.alertPopUp = false;
              this.zipAreaForm.reset();
            }
          },
          error: (err) => {
            if (err) {
              // console.log(err);
              // this.alertService.error(err.error.error, { autoClose: true });
            }
          },
        });
      this.zipAreaForm.reset();
    }
  }

  scrollToServices() {
    if (this.servicesSection) {
      this.servicesSection.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  scrollToFaq() {
    if (this.faqSection) {
      this.faqSection.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  scrollToComments() {
    if (this.commentSection) {
      this.commentSection.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  updateSwiper() {
    if (this.swiper) {
      this.swiper.destroy();
    }

    this.swiperParams = {
      slidesPerView: 1,
      loop: true,
      spaceBetween: 20,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        1280: {
          slidesPerView: 3,
          spaceBetween: 50,
        }
      },
      on: {
        init: () => {
          // console.log('Swiper initialized');
        }
      }
    };

    this.swiper = new SwiperCore(this.swiperContainerRef.nativeElement, this.swiperParams);
  }

  currentView() {
    if(this.servicesSection){
      this.services = true
      console.log(this.services)
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.swiper?.update();
  }
  
  ngOnDestroy() {
    if (this.subscription && this.subscriptionFaq) {
      this.subscription.unsubscribe();
      this.subscriptionFaq.unsubscribe();
      this.subscriptionComments.unsubscribe();
    }
  }
}
