import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  standalone: true,
})
export class FilterPipe implements PipeTransform {
  transform(
    items: any[],
    searchText: string | null,
    properties: string[]
  ): any[] {
    if (!items) {
      return [];
    }
    if (!searchText || !properties || properties.length === 0) {
      return items;
    }

    searchText = searchText.toLowerCase();
    return items.filter((it) => this.searchNested(it, properties, searchText!));
  }

  private searchNested(
    item: any,
    properties: string[],
    searchText: string
  ): boolean {
    if (!item || properties.length === 0) {
      return false;
    }

    for (const property of properties) {
      const keys = property.split('.');
      const value = this.getPropertyValue(item, keys);

      if (
        this.isSearchable(value) &&
        this.includesSearchText(value, searchText)
      ) {
        return true;
      }
    }

    return false;
  }

  private getPropertyValue(item: any, keys: string[]): any {
    let value = item;
    for (const key of keys) {
      if (value.hasOwnProperty(key)) {
        value = value[key];
      } else {
        return undefined;
      }
    }
    return value;
  }

  private isSearchable(value: any): boolean {
    return typeof value === 'string' || typeof value === 'number';
  }

  private includesSearchText(value: any, searchText: string): boolean {
    if (this.isValidDate(searchText)) {
      return this.filterByDate(value, searchText);
    }
    if (typeof value === 'string') {
      return value.toLowerCase().includes(searchText);
    }
    if (typeof value === 'number') {
      return value.toString().includes(searchText);
    }
    return false;
  }

  private isValidDate(dateString: string): boolean {
    var dateRegex =
      /^([1-9]|0[1-9]|[12][0-9]|3[01])[\/\.]([1-9]|0[1-9]|1[0-2])[\/\.](\d{4})$/;
    return dateRegex.test(dateString);
  }

  private filterByDate(item: string, userDate: string) {
    let day, month, year;

    if (userDate.includes('.')) {
      [day, month, year] = userDate.split('.');
    } else if (userDate.includes('/')) {
      [day, month, year] = userDate.split('/');
    } else {
      return false;
    }

    month = month.length === 1 ? '0' + month : month;
    day = day.length === 1 ? '0' + day : day;

    const userSelectedDate = new Date(`${year}-${month}-${day}`);
    const itemDate = new Date(item);

    if (isNaN(userSelectedDate.getTime())) {
      return false;
    }
    if (isNaN(itemDate.getTime())) {
      return false;
    }

    userSelectedDate.setUTCHours(0, 0, 0, 0);
    itemDate.setUTCHours(0, 0, 0, 0);

    return itemDate.toISOString() === userSelectedDate.toISOString();
  }
}
