<section
  class="bg-green-primary break-words hyphens-auto mx-5 rounded-tl-3xl rounded-br-3xl md:mb-16 2xl:mb-20"
>
  <div class="container mx-auto">
    <div class="flex flex-col md:flex-row justify-between md:pt-10 2xl:pt-16">
      <div class="py-5 md:w-[50%] mx-5 md:mx-10">
        <p
          class="bg-violet-secondary w-fit text-violet-brightpurple rounded-md uppercase px-5 font-bold font-sans md:mb-5"
        >
          {{ "facts-component.number-facts" | translate }}
        </p>
        <h4 class="text-gray-duckfortiery font-bold">
          {{ "facts-component.title" | translate}}
        </h4>
      </div>
      <div class="md:py-5 mx-5 md:mx-10 md:w-[50%] md:mt-10">
        <p class="md:py-5">
          {{ "facts-component.content" | translate}}
        </p>
      </div>
    </div>
    <div class="flex flex-col text-[#ced86b] md:flex-row items-center gap-5 justify-between py-10 pb-16">
      <div class="md:w-[25%] w-[50%] flex flex-col items-center justify-center">
        <img class="w-20" src="../../../assets/icons/noun-3794405.svg" alt="random">
        <p class="special-font -mb-[10px]">{{ "facts-component.about" | translate }}</p>
        <p class="number-font -mb-[10px]">15</p>
        <p class="special-font">{{ "facts-component.years" | translate }}</p>
        <p class="special-font -mb-[10px]">{{ "facts-component.experience" | translate }}</p>
      </div>
      <div class="md:w-[25%] w-[50%] flex flex-col items-center justify-center">
        <img class="w-20" src="../../../assets/icons/noun-3794302.svg" alt="random">
        <p class="special-font -mb-[10px]">{{ "facts-component.approx" | translate }}</p>
        <p class="number-font -mb-[10px]">100</p>
        <p class="special-font">{{ "facts-component.current" | translate }}</p>
        <p class="special-font -mb-[10px]">{{ "facts-component.employees" | translate }}</p>
      </div>
      <div class="md:w-[25%] w-[50%] flex flex-col items-center justify-center">
        <img class="w-24" src="../../../assets/icons/noun-7043107.svg" alt="random">
        <p class="special-font -my-[10px]">{{ "facts-component.overall" | translate }}</p>
        <p class="number-font -mb-[10px]">10K</p>
        <p class="special-font">{{ "facts-component.satisfied" | translate }}</p>
        <p class="special-font -mb-[10px]">{{ "facts-component.privatecustomers" | translate }}</p>
      </div>
      <div class="md:w-[25%] w-[50%] flex flex-col items-center justify-center">
        <img class="w-[90px] -mt-2" src="../../../assets/icons/noun-3794034.svg" alt="random">
        <p class="special-font mt-[5px] -mb-[10px]">{{ "facts-component.overall" | translate }}</p>
        <p class="number-font -mb-[10px]">2K</p>
        <p class="special-font">{{ "facts-component.supported" | translate }}</p>
        <p class="special-font -mb-[10px]">{{ "facts-component.business" | translate }}</p>
      </div>
    </div>
  </div>
</section>
