<div
[ngClass]="{'header-gradient': changeBg === false, 
'bg-white': changeBg === true && !isOpen}"
class="header-gradient break-words hyphens-auto relative">
  <div class="md:mx-12 mx-5 py-2 flex justify-between items-center">
    <a routerLink="/"
      ><img
        src="../../../assets/icons/color-logo.svg"
        class="z-20 w-[110px]"
        alt="random"
    /></a>
    <span class="flex justify-between gap-4">
      <button
      *ngIf="!isOpen"
      [ngClass]="{'border-white text-white' : changeBg === false,
      'border-black text-black' : changeBg === true }"
        class="border-[1px] hover:bg-[#ededed3a] hover:duration-100 flex justify-center gap-2 items-center border-white text-white rounded-[6px] px-8 py-2"
        (click)="handleLanguage()"
      >
        {{ getSelectedLang() }}
        @if(changeBg && !isOpen) {
          <img
            src="../../../assets/icons/navigation-language-logo-black.svg"
            alt="icon"
          />
        } @else {
          <img
            src="../../../assets/icons/navigation-language-logo.svg"
            alt="icon"
          />
        }
      </button>
      <button
        *ngIf="isOpen"
        class="border-[1px] hover:bg-[#ededed3a] hover:duration-100 flex justify-center gap-2 items-center border-white text-white rounded-[6px] px-8 py-2"
        (click)="handleLanguage()"
      >
        {{ getSelectedLang() }}
        @if(changeBg && !isOpen) {
          <img
            src="../../../assets/icons/navigation-language-logo-black.svg"
            alt="icon"
          />
        } @else {
          <img
            src="../../../assets/icons/navigation-language-logo.svg"
            alt="icon"
          />
        }
      </button>
      <button
      [ngClass]="{'border-black': changeBg && !isOpen, 'border-white': !changeBg}"
        class="border-[1px] hover:bg-[#ededed3a] hover:duration-100 flex flex-col justify-center items-center gap-1 text-white rounded-[6px] px-4 py-2"
        (click)="handleMenu()"
      >
      @if(changeBg && !isOpen) {
        <span
          [ngClass]="{
            'bg-black': changeBg && !isOpen,
            'w-[20px] h-[2px] transition ease transform duration-300': true,
            'rotate-45 translate-y-2 group-hover:opacity-100 duration-100':
              isOpen
          }"
        ></span>
        <span
          [ngClass]="{
            'bg-black': changeBg && !isOpen,
            'w-[20px] h-[2px] transition ease transform duration-300': true,
            'opacity-0 duration-100': isOpen
          }"
        ></span>
        <span
          [ngClass]="{
            'bg-black': changeBg && !isOpen,
            'w-[20px] h-[2px] transition ease transform duration-300': true,
            '-rotate-45 -translate-y-1 group-hover:opacity-100 duration-100':
              isOpen
          }"
        ></span>
      } @else {
        <span
          [ngClass]="{
            'w-[20px] h-[2px] transition bg-white ease transform duration-300': true,
            'rotate-45 translate-y-2 group-hover:opacity-100 duration-100':
              isOpen
          }"
        ></span>
        <span
          [ngClass]="{
            'w-[20px] h-[2px] transition bg-white ease transform duration-300': true,
            'opacity-0 duration-100': isOpen
          }"
        ></span>
        <span
          [ngClass]="{
            'w-[20px] h-[2px] transition bg-white ease transform duration-300': true,
            '-rotate-45 -translate-y-1 group-hover:opacity-100 duration-100':
              isOpen
          }"
        ></span>
      }
      </button>
    </span>
  </div>
  <div class="fixed z-50 top-10 modal mx-auto">
    <app-alert></app-alert>
  </div>
</div>
