import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
// Components
import { FactsComponent } from '../components/companyfacts/facts.component';
import { ProcessComponent } from '../components/companyprocess/process.component';
import { CareerComponent } from '../components/companycareer/career.component';
import { CardComponent } from '../components/ceocard/card.component';
@Component({
  selector: 'app-aboutus',
  standalone: true,
  imports: [
    FactsComponent,
    ProcessComponent,
    CareerComponent,
    CardComponent,
    CommonModule,
    TranslateModule,
  ],
  templateUrl: './about.component.html',
  styleUrl: './about.component.scss',
})
export class AboutComponent {
  directorCards = [
    {
      title: 'Pamela Secaira',
      desc: 'Geschäftsführung',
      phone: '+43 123 56 78',
      email: 'test.user@pebra.ch',
      img: '../../assets/images/pebrateam1.jpg',
    },
    {
      title: 'Barbara Lopes',
      desc: 'Qualitätsmanagement und Aussendienst',
      phone: '+43 123 56 78',
      email: 'test.user@pebra.ch',
      img: '../../assets/images/pebrateam2.jpg',
    },
    {
      title: 'Carmen Vecchi',
      desc: 'Kundendienst',
      phone: '+43 123 56 78',
      email: 'test.user@pebra.ch',
      img: '../../assets/images/pebrateam3.jpg',
    },
    {
      title: 'Catarina Ribeiro de Carvalho',
      desc: 'Disposition',
      phone: '+43 123 56 78',
      email: 'test.user@pebra.ch',
      img: '../../assets/images/homeimg2.png',
    },
  ];
}
