@if (show) {
<div
[ngClass]="{'header-gradient': changeBg === false, 
'bg-white': changeBg === true && !isOpen}"
class="header-gradient break-words hyphens-auto relative">
  <div class="md:mx-12 mx-5 py-2 flex justify-between items-center">
    <a routerLink="/"
      >
      @if(isContact){
          <img
          (click)="scrollToTop()"
          src="../../../assets/icons/logo-white.svg"
          class="z-20 w-[110px] cursor-pointer"
          alt="random"
        />
      }@else {
        <img
          src="../../../assets/icons/color-logo.svg"
          class="z-20 w-[110px]"
          alt="random"
        />
      }
    </a>
    <span class="flex justify-between gap-4">
      <button
      *ngIf="!isOpen"
      [ngClass]="{'border-white text-white' : changeBg === false,
      'border-black text-black' : changeBg === true }"
        class="border-[1px] hover:bg-[#ededed3a] hover:duration-100 flex justify-center gap-2 items-center border-white text-white rounded-[6px] px-8 py-2"
        (click)="handleLanguage()"
      >
        {{ getSelectedLang() }}
        @if(changeBg && !isOpen) {
          <img
            src="../../../assets/icons/navigation-language-logo-black.svg"
            alt="icon"
          />
        } @else {
          <img
            src="../../../assets/icons/navigation-language-logo.svg"
            alt="icon"
          />
        }
      </button>
      <button
        *ngIf="isOpen"
        class="border-[1px] hover:bg-[#ededed3a] hover:duration-100 flex justify-center gap-2 items-center border-white text-white rounded-[6px] px-8 py-2"
        (click)="handleLanguage()"
      >
        {{ getSelectedLang() }}
        @if(changeBg && !isOpen) {
          <img
            src="../../../assets/icons/navigation-language-logo-black.svg"
            alt="icon"
          />
        } @else {
          <img
            src="../../../assets/icons/navigation-language-logo.svg"
            alt="icon"
          />
        }
      </button>
      <button
      [ngClass]="{'border-black': changeBg && !isOpen, 'border-white': !changeBg}"
        class="border-[1px] hover:bg-[#ededed3a] hover:duration-100 flex flex-col justify-center items-center gap-1 text-white rounded-[6px] px-4 py-2"
        (click)="handleMenu()"
      >
      @if(changeBg && !isOpen) {
        <span
          [ngClass]="{
            'bg-black': changeBg && !isOpen,
            'w-[20px] h-[2px] transition ease transform duration-300': true,
            'rotate-45 translate-y-2 group-hover:opacity-100 duration-100':
              isOpen
          }"
        ></span>
        <span
          [ngClass]="{
            'bg-black': changeBg && !isOpen,
            'w-[20px] h-[2px] transition ease transform duration-300': true,
            'opacity-0 duration-100': isOpen
          }"
        ></span>
        <span
          [ngClass]="{
            'bg-black': changeBg && !isOpen,
            'w-[20px] h-[2px] transition ease transform duration-300': true,
            '-rotate-45 -translate-y-1 group-hover:opacity-100 duration-100':
              isOpen
          }"
        ></span>
      } @else {
        <span
          [ngClass]="{
            'w-[20px] h-[2px] transition bg-white ease transform duration-300': true,
            'rotate-45 translate-y-2 group-hover:opacity-100 duration-100':
              isOpen
          }"
        ></span>
        <span
          [ngClass]="{
            'w-[20px] h-[2px] transition bg-white ease transform duration-300': true,
            'opacity-0 duration-100': isOpen
          }"
        ></span>
        <span
          [ngClass]="{
            'w-[20px] h-[2px] transition bg-white ease transform duration-300': true,
            '-rotate-45 -translate-y-1 group-hover:opacity-100 duration-100':
              isOpen
          }"
        ></span>
      }
      </button>
    </span>
  </div>
  <div class="fixed z-50 top-10 modal mx-auto">
    <app-alert></app-alert>
  </div>
</div>
} @else {
  <div id="navbar"
[ngClass]="{
  'border-b border-[#00829A]': isOpen,
  'bg-transparent' : isContact,
  'bg-white' : !isContact
}"
class=" break-words hyphens-none relative">
  <div class="md:mx-12 mx-5 py-2 flex justify-between items-center h-[64px]">
    <a (click)="scrollToTop()"
      > @if(isContact){
        <img
        src="../../../assets/icons/logo-white.svg"
        class="z-20 w-[110px]"
        alt="random"
      />
      }@else {
        <img
          src="../../../assets/icons/color-logo.svg"
          class="z-20 w-[110px]"
          alt="random"
        />
      }</a>
    <div class="hidden lg:flex flex-row gap-[68px]">
      <a
      [ngClass]="{
        'text-turquoise-duckfortiery': this.currentSection === 'home',
        'text-white hover:text-[#61366E]': isContact,
        'text-[#61366E] hover:text-turquoise-duckfortiery' :!isContact
      }"
        (click)="scrollToTop()"
        class="text-turquoise-duckfortiery  cursor-pointer uppercase text-[18px]  hover:duration-100 font-bold"
      >
        HOME
      </a>
      <button
      [ngClass]="{
        'text-turquoise-duckfortiery': this.currentSection === 'servicesSection',
        'text-white hover:text-[#61366E]': isContact,
        'text-[#61366E] hover:text-turquoise-duckfortiery' :!isContact
      }"
        (click)="navigateToServices()"
        class=" uppercase text-[18px]  hover:duration-100 text-left md:text-right font-bold"
      >
        Unsere Leistungen
      </button>
      <button
      [ngClass]="{
        'text-turquoise-duckfortiery': this.currentSection === 'commentSection',
        'text-white hover:text-[#61366E]': isContact,
        'text-[#61366E] hover:text-turquoise-duckfortiery' :!isContact
      }"
      (click)="navigateToComments()"
        class=" uppercase cursor-pointer text-[18px]  hover:duration-100 font-bold"
      >
        Kundenstimmen
      </button>
      <button
      [ngClass]="{
        'text-turquoise-duckfortiery': this.currentSection === 'faqSection',
        'text-white hover:text-[#61366E]': isContact,
        'text-[#61366E] hover:text-turquoise-duckfortiery' :!isContact
      }"
        (click)="navigateToFaq()"
        class="uppercase text-[18px] hover:duration-100 font-bold"
      >
        FAQ
      </button>
    </div>
    <div class="hidden  lg:block">
      <a
      *ngIf="showBtn"
      href="https://{{pebraApp}}/private"
      class="text-[#61366E] uppercase text-[18px] hover:text-turquoise-duckfortiery cursor-pointer hover:duration-100 font-bold"
    >
      Jetzt buchen
    </a>
    </div>
    <span class="flex lg:hidden justify-between gap-4">
      <button
        class="flex flex-col justify-center items-center gap-1 text-white px-4 py-2"
        (click)="handleMenu()"
      >
        <span
          [ngClass]="{
            'w-[30px] h-[2px] transition ease transform duration-300 bg-[#00829A]': true,
            'rotate-45 translate-y-2 group-hover:opacity-100 duration-100 bg-[#61366E]':
              isOpen
          }"
        ></span>
        <span
          [ngClass]="{
            'w-[30px] h-[2px] transition ease transform duration-300 bg-[#003494]': true,
            'opacity-0 duration-100': isOpen
          }"
        ></span>
        <span
          [ngClass]="{
            'w-[30px] h-[2px] transition ease transform duration-300 bg-[#61366E]': true,
            '-rotate-45 -translate-y-1 group-hover:opacity-100 duration-100 !bg-[#00829A]':
              isOpen
          }"
        ></span>
      </button>
    </span>
  </div>
  <div class="fixed z-50 top-10 modal mx-auto">
    <app-alert></app-alert>
  </div>
</div>

}