import { Component, AfterViewInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';

declare var hbspt: any;

@Component({
  selector: 'app-hubspot-form',
  templateUrl: './hubspot-form.component.html',
  standalone: true,
  styleUrls: ['./hubspot-form.component.scss']
})
export class HubspotFormComponent implements AfterViewInit {
  isLoading = true; // Start with loading state

  ngAfterViewInit(): void {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/embed/49530899.js';
    script.defer = true;
    document.body.appendChild(script);

    // Check when the HubSpot form is loaded
    setTimeout(() => {
      this.isLoading = false; // Hide loader when form loads
    }, 3000); // Adjust delay if needed
  }
}