import { Component, HostListener, OnInit } from '@angular/core';
import {
  NavigationEnd,
  Router,
  RouterModule,
  RouterOutlet,
} from '@angular/router';
import { CommonModule } from '@angular/common';

import { filter } from 'rxjs/operators';

// Components
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { TranslateService } from '@ngx-translate/core';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageService } from './services/language.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [
    CommonModule,
    FooterComponent,
    HeaderComponent,
    RouterModule,
    RouterOutlet,
    TranslateModule,
  ],
})
export class AppComponent implements OnInit {
  title: string = '';
  public isOpen: boolean = false;
  public showButton: boolean = false;
  public lastScrollPosition: number = 0;
  private defaultLanguage: string = 'DE';
  reinigung: string = 'Reinigung';
  public selectedLanguage?: string;
  isLanguageOpen: boolean = false;
  public token: string | null;
  public loginBtn: boolean = false;
  public pebraApp: string = environment.appUrl
  
  constructor(
    private router: Router,
    private translate: TranslateService,
    private languageService: LanguageService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        window.scrollTo(0, 0);
      });
    this.token = localStorage.getItem('token');
  }

  ngOnInit() {
    this.handleButton();
    this.selectedLanguage =
      localStorage.getItem('selectedLanguage') || this.defaultLanguage;
    this.languageService.setSelectedLanguage(this.selectedLanguage);
    if (this.token) {
      this.loginBtn = true;
    } else {
      this.loginBtn = false;
    }
  }

  selectLang(lang: string) {
    if (lang === 'EN') {
      this.selectedLanguage = 'EN';
      this.translate.use('en');
      localStorage.setItem('selectedLanguage', 'EN');
    } else if (lang === 'DE') {
      this.selectedLanguage = 'DE';
      this.translate.use('de');
      localStorage.setItem('selectedLanguage', 'DE');
    }
    this.isLanguageOpen = false;
  }
  
  handleOpenLanguage() {
    this.isLanguageOpen = !this.isLanguageOpen;
  }

  handleButton() {
    if (window.innerWidth <= 2560 && window.innerWidth >= 768) {
      this.reinigung = 'Reinigung reservieren';
    } else {
      this.reinigung = 'Reinigung';
    }
  }

  handleIsOpenChange(isOpen: boolean) {
    this.isOpen = isOpen;
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    this.showButton = scrollPosition > 100;
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
