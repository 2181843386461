import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alertservice/alert.service';
import { CommonModule } from '@angular/common';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [RouterModule, TranslateModule, ReactiveFormsModule, CommonModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  newsLetterForm = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email])
  })
  public pebraApp: string = environment.appUrl


  constructor(private services: ApiService, private alertServices: AlertService) {}

  handleSubmitLetter() {
    if(this.newsLetterForm.valid) {
      if(this.newsLetterForm.get('email')?.hasError('required') && this.newsLetterForm.get('email')?.errors){
        console.log("error");
      } else {
        this.services.newsLetter(this.newsLetterForm.value).subscribe({
          next: (res) => {
            if (res) {
              this.alertServices.success("Erfolgreich eingetragen", { autoClose: true });
              this.newsLetterForm.get('email')?.setValue(null)      
            }
          },
          error: (err) => {
            if (err) {
              this.alertServices.error(err.error.error , {autoClose: true})
            }
          }
        }) 
      }
    } else {
      console.log("error"); 
    }
  }
  
  openFile() {
    window.open("../../../assets/terms/AGB_Pebra_Reinigung_Schweiz_AG_2024.pdf")
  }
}
